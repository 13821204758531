import {
    ALL_ANGGARAN,
    ALL_ANGGARAN_REVIEW,
    ALL_ANGGARAN_TAHUNAN,
    ALL_ANGGARAN_BULANAN,
    ALL_ANGGARAN_REPORT,
    ALL_ANGGARAN_NOTE,
    GIVE_NOTE,
    COST_CENTRE_LIST,
    LATEST_THN_ANGGARAN,
    APPROVAL,
    SET_LOADING,
    SET_ANOTHER_LOADING,
    VALIDATION_ERROR,
    DETAIL_REALISASI_PROGNOSA,
    CHART_REALISASI_PROGNOSA,
} from ".";

const initialState = {
    allAnggaran: [],
    allAnggaranReview: [],
    allAnggaranTahunan: [],
    allAnggaranBulanan: [],
    allAnggaranReport: [],
    allAnggaranNote: [],
    costCentreList: [],
    latestTahun: "",
    giveNotes: "",
    approval: "",
    isLoading: false,
    isAnotherLoading: false,
    validationError: {},
    detailRealisasiPrognosa: {},
    chartRealisasiPrognosa: {},
};

export default function anggaranAdminReducer(state = initialState, action) {
    switch (action.type) {
        case ALL_ANGGARAN:
            return { ...state, allAnggaran: action.payload };
        case ALL_ANGGARAN_REVIEW:
            return { ...state, allAnggaranReview: action.payload };
        case ALL_ANGGARAN_TAHUNAN:
            return { ...state, allAnggaranTahunan: action.payload };
        case ALL_ANGGARAN_BULANAN:
            return { ...state, allAnggaranBulanan: action.payload };
        case ALL_ANGGARAN_REPORT:
            return { ...state, allAnggaranReport: action.payload };
        case ALL_ANGGARAN_NOTE:
            return { ...state, allAnggaranNote: action.payload };
        case GIVE_NOTE:
            return { ...state, giveNotes: action.payload };
        case COST_CENTRE_LIST:
            return { ...state, costCentreList: action.payload };
        case LATEST_THN_ANGGARAN:
            return { ...state, latestTahun: action.payload };
        case APPROVAL:
            return { ...state, approval: action.payload };
        case SET_LOADING:
            return { ...state, isLoading: action.payload };
        case SET_ANOTHER_LOADING:
            return { ...state, isAnotherLoading: action.payload };
        case VALIDATION_ERROR:
            return { ...state, validationError: action.payload };
        case DETAIL_REALISASI_PROGNOSA:
            return { ...state, detailRealisasiPrognosa: action.payload };
        case CHART_REALISASI_PROGNOSA:
            return { ...state, chartRealisasiPrognosa: action.payload };
        default:
            return state;
    }
}
