import {
    SET_LOADING,
    DATA_POSISI_KEUANGAN_ALL,
    DATA_PENGHASILAN_KOMPREHENSIF_ALL,
    DATA_DAFTAR_JURNAL_ALL,
    DATA_NERACA_LAJUR_ALL,
    DATA_BUKU_BESAR_ALL,
    DATA_POSISI_KEUANGAN_ENTITAS,
    DATA_PENGHASILAN_KOMPREHENSIF_ENTITAS,
    DATA_DAFTAR_JURNAL_ENTITAS,
    DATA_NERACA_LAJUR_ENTITAS,
    DATA_BUKU_BESAR_ENTITAS,
} from ".";

const initialState = {
    dataPosisiKeuanganAll: [],
    dataKomprehensifAll: [],
    dataDaftarJurnalAll: [],
    dataNeracaLajurAll: [],
    dataBukuBesarAll: [],
    dataPosisiKeuanganEntitas: [],
    dataKomprehensifEntitas: [],
    dataDaftarJurnalEntitas: [],
    dataNeracaLajurEntitas: [],
    dataBukuBesarEntitas: [],
    isLoading: false,
};

export default function laporanReducer(state = initialState, action) {
    switch (action.type) {
        case DATA_POSISI_KEUANGAN_ALL:
            return { ...state, dataPosisiKeuanganAll: action.payload };
        case DATA_PENGHASILAN_KOMPREHENSIF_ALL:
            return { ...state, dataKomprehensifAll: action.payload };
        case DATA_DAFTAR_JURNAL_ALL:
            return { ...state, dataDaftarJurnalAll: action.payload };
        case DATA_NERACA_LAJUR_ALL:
            return { ...state, dataNeracaLajurAll: action.payload };
        case DATA_BUKU_BESAR_ALL:
            return { ...state, dataBukuBesarAll: action.payload };
        case DATA_POSISI_KEUANGAN_ENTITAS:
            return { ...state, dataPosisiKeuanganEntitas: action.payload };
        case DATA_PENGHASILAN_KOMPREHENSIF_ENTITAS:
            return { ...state, dataKomprehensifEntitas: action.payload };
        case DATA_DAFTAR_JURNAL_ENTITAS:
            return { ...state, dataDaftarJurnalEntitas: action.payload };
        case DATA_NERACA_LAJUR_ENTITAS:
            return { ...state, dataNeracaLajurEntitas: action.payload };
        case DATA_BUKU_BESAR_ENTITAS:
            return { ...state, dataBukuBesarEntitas: action.payload };
        case SET_LOADING:
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}
