import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function AdminRoute({component: Component, ...restProps}) {
    const admin = localStorage.getItem('isAdmin');

    return <Route
        {...restProps}
        render = {props => {
            return admin ? <Component {...props} /> : <Redirect to={{pathname: '/login'}} />
        }}
    />
}

export default AdminRoute;