import {
    WORKFLOW_LIST,
    DATA_UPDATED,
    DATA_EDIT,
    VALIDATION_ERROR,
    ALL_UNIT,
    ALL_APPROVER,
    ALL_DATA_APPROVER,
    SET_LOADING,
    SET_LOADING_UNIT,
    SET_LOADING_APPROVER,
} from ".";

const initialState = {
    workflowList: [],
    dataUpdated: "",
    dataEdit: {},
    units: [],
    approvers: [],
    dataApprovers: [],
    validationError: {},
    isLoading: false,
    isLoadingOptUnit: false,
    isLoadingOptApprovers: false,
};

export default function workflowReducer(state = initialState, action) {
    switch (action.type) {
        case WORKFLOW_LIST:
            return { ...state, workflowList: action.payload };
        case ALL_UNIT:
            return { ...state, units: action.payload };
        case ALL_APPROVER:
            return { ...state, approvers: action.payload };
        case ALL_DATA_APPROVER:
            return { ...state, dataApprovers: action.payload };
        case DATA_UPDATED:
            return { ...state, dataUpdated: action.payload };
        case DATA_EDIT:
            return { ...state, dataEdit: action.payload };
        case VALIDATION_ERROR:
            return { ...state, validationError: action.payload };
        case SET_LOADING:
            return { ...state, isLoading: action.payload };
        case SET_LOADING_UNIT:
            return { ...state, isLoadingOptUnit: action.payload };
        case SET_LOADING_APPROVER:
            return { ...state, isLoadingOptApprovers: action.payload };
        default:
            return state;
    }
}