import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function FinanceHeadRoute({component: Component, ...restProps}) {
    const financeHead = localStorage.getItem('isFinanceHead');

    return <Route
        {...restProps}
        render = {props => {
            return financeHead ? <Component {...props} /> : <Redirect to={{pathname: '/login'}} />
        }}
    />
}

export default FinanceHeadRoute;