import {
    SET_LOADING_LOG_TRANSAKSI,
    SET_LOADING_LOG_PENGAJUAN,
    SET_LOADING_LOG_AKTIFITAS_PENGGUNA,
    LOG_TRANSAKSI,
    LOG_PENGAJUAN,
    LOG_AKTIFITAS_PENGGUNA,
    STATISTIK,
} from ".";

const initialState = {
    statistik: {},
    logTransaksi: [],
    logPengajuan: [],
    logAktifitasPengguna: [],
    isLoadingLogTransaksi: false,
    isLoadingLogPengajuan: false,
    isLoadingLogAktifitas: false,
};

export default function dashboardReducer(state = initialState, action) {
    switch (action.type) {
        case STATISTIK:
            return { ...state, statistik: action.payload };
        case LOG_TRANSAKSI:
            return { ...state, logTransaksi: action.payload };
        case LOG_PENGAJUAN:
            return { ...state, logPengajuan: action.payload };
        case LOG_AKTIFITAS_PENGGUNA:
            return { ...state, logAktifitasPengguna: action.payload };
        case SET_LOADING_LOG_TRANSAKSI:
            return { ...state, isLoadingLogTransaksi: action.payload };
        case SET_LOADING_LOG_PENGAJUAN:
            return { ...state, isLoadingLogPengajuan: action.payload };
        case SET_LOADING_LOG_AKTIFITAS_PENGGUNA:
            return { ...state, isLoadingLogAktifitas: action.payload };
        default:
            return state;
    }
}
