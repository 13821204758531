import {
    LIST_INSTANSI,
    SET_INSTANSI,
    ALL_KODE_DANA,
    ALL_AKUN1,
    ALL_AKUN2,
    ALL_AKUN3,
    ALL_AKUN4,
    ALL_AKUN5,
    ALL_PUSAT_BIAYA1,
    ALL_PUSAT_BIAYA2,
    ALL_PUSAT_BIAYA3,
    ALL_PROGRAM1,
    ALL_PROGRAM2,
    ALL_KAS1,
    ALL_KAS2,
    ALL_KAS3,
    KODE_DANA_UPDATED,
    KODE_AKUN_UPDATED1,
    KODE_AKUN_UPDATED2,
    KODE_AKUN_UPDATED3,
    KODE_AKUN_UPDATED4,
    KODE_AKUN_UPDATED5,
    KODE_PUSAT_BIAYA_UPDATED1,
    KODE_PUSAT_BIAYA_UPDATED2,
    KODE_PUSAT_BIAYA_UPDATED3,
    KODE_PROGRAM_UPDATED1,
    KODE_PROGRAM_UPDATED2,
    KODE_KAS_UPDATED1,
    KODE_KAS_UPDATED2,
    KODE_KAS_UPDATED3,
    USERS_PUSAT_BIAYA,
    USERS_PUSAT_BIAYA_UPDATED,
    KODE_AKUN_CC,
    KODE_AKUN_CC_UPDATED,
    ALL_KODE_AKUN5,
    VALIDATION_ERROR,
    SET_LOADING,
    SET_LOADING_ALL_KODE_AKUN,
} from ".";

const initialState = {
    listInstansi: [],
    instansi: "",
    allKodeDana: [],
    allKodeAkun1: [],
    allKodeAkun2: [],
    allKodeAkun3: [],
    allKodeAkun4: [],
    allKodeAkun5: [],
    allKodePusatBiaya1: [],
    allKodePusatBiaya2: [],
    allKodePusatBiaya3: [],
    allKodeProgram1: [],
    allKodeProgram2: [],
    allKodeKas1: [],
    allKodeKas2: [],
    allKodeKas3: [],
    usersPusatBiaya: [],
    costCentreList: [],
    kodeDanaUpdated: "",
    kodeAkunUpdated1: "",
    kodeAkunUpdated2: "",
    kodeAkunUpdated3: "",
    kodeAkunUpdated4: "",
    kodeAkunUpdated5: "",
    kodePusatBiayaUpdated1: "",
    kodePusatBiayaUpdated2: "",
    kodePusatBiayaUpdated3: "",
    kodeProgramUpdated1: "",
    kodeProgramUpdated2: "",
    kodeKasUpdated1: "",
    kodeKasUpdated2: "",
    kodeKasUpdated3: "",
    usersPusatBiayaUpdated: "",
    costCentreListUpdated: "",
    kodeAkun5: [],
    validationError: {},
    isLoading: false,
    isLoadingKodeAkun: false,
};

export default function kodeBukuBesarReducer(state = initialState, action) {
    switch (action.type) {
        case LIST_INSTANSI:
            return { ...state, listInstansi: action.payload };
        case SET_INSTANSI:
            return { ...state, instansi: action.payload };
        case ALL_KODE_DANA:
            return { ...state, allKodeDana: action.payload };
        case ALL_AKUN1:
            return { ...state, allKodeAkun1: action.payload };
        case ALL_AKUN2:
            return { ...state, allKodeAkun2: action.payload };
        case ALL_AKUN3:
            return { ...state, allKodeAkun3: action.payload };
        case ALL_AKUN4:
            return { ...state, allKodeAkun4: action.payload };
        case ALL_AKUN5:
            return { ...state, allKodeAkun5: action.payload };
        case ALL_PUSAT_BIAYA1:
            return { ...state, allKodePusatBiaya1: action.payload };
        case ALL_PUSAT_BIAYA2:
            return { ...state, allKodePusatBiaya2: action.payload };
        case ALL_PUSAT_BIAYA3:
            return { ...state, allKodePusatBiaya3: action.payload };
        case ALL_PROGRAM1:
            return { ...state, allKodeProgram1: action.payload };
        case ALL_PROGRAM2:
            return { ...state, allKodeProgram2: action.payload };
        case ALL_KAS1:
            return { ...state, allKodeKas1: action.payload };
        case ALL_KAS2:
            return { ...state, allKodeKas2: action.payload };
        case ALL_KAS3:
            return { ...state, allKodeKas3: action.payload };
        case KODE_DANA_UPDATED:
            return { ...state, kodeDanaUpdated: action.payload };
        case KODE_AKUN_UPDATED1:
            return { ...state, kodeAkunUpdated1: action.payload };
        case KODE_AKUN_UPDATED2:
            return { ...state, kodeAkunUpdated2: action.payload };
        case KODE_AKUN_UPDATED3:
            return { ...state, kodeAkunUpdated3: action.payload };
        case KODE_AKUN_UPDATED4:
            return { ...state, kodeAkunUpdated4: action.payload };
        case KODE_AKUN_UPDATED5:
            return { ...state, kodeAkunUpdated5: action.payload };
        case KODE_PUSAT_BIAYA_UPDATED1:
            return { ...state, kodePusatBiayaUpdated1: action.payload };
        case KODE_PUSAT_BIAYA_UPDATED2:
            return { ...state, kodePusatBiayaUpdated2: action.payload };
        case KODE_PUSAT_BIAYA_UPDATED3:
            return { ...state, kodePusatBiayaUpdated3: action.payload };
        case KODE_PROGRAM_UPDATED1:
            return { ...state, kodeProgramUpdated1: action.payload };
        case KODE_PROGRAM_UPDATED2:
            return { ...state, kodeProgramUpdated2: action.payload };
        case KODE_KAS_UPDATED1:
            return { ...state, kodeKasUpdated1: action.payload };
        case KODE_KAS_UPDATED2:
            return { ...state, kodeKasUpdated2: action.payload };
        case KODE_KAS_UPDATED3:
            return { ...state, kodeKasUpdated3: action.payload };
        case USERS_PUSAT_BIAYA:
            return { ...state, usersPusatBiaya: action.payload };
        case USERS_PUSAT_BIAYA_UPDATED:
            return { ...state, usersPusatBiayaUpdated: action.payload };
        case KODE_AKUN_CC:
            return { ...state, costCentreList: action.payload };
        case KODE_AKUN_CC_UPDATED:
            return { ...state, costCentreListUpdated: action.payload };
        case ALL_KODE_AKUN5:
            return { ...state, kodeAkun5: action.payload };
        case VALIDATION_ERROR:
            return { ...state, validationError: action.payload };
        case SET_LOADING:
            return { ...state, isLoading: action.payload };
        case SET_LOADING_ALL_KODE_AKUN:
            return { ...state, isLoadingKodeAkun: action.payload };
        default:
            return state;
    }
}
