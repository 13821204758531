export const SET_LOADING = "SET_LOADING";
export const SET_ANOTHER_LOADING = "SET_ANOTHER_LOADING";
export const SET_OTHER_LOADING = "SET_OTHER_LOADING";
export const ALL_DATA_PPBJ = "ALL_DATA_PPBJ";
export const ALL_DATA_PTUM = "ALL_DATA_PTUM";
export const ALL_DATA_BKKB = "ALL_DATA_BKKB";
export const ALL_DATA_BTKB = "ALL_DATA_BTKB";
export const ALL_DATA_BJ = "ALL_DATA_BJ";
export const DETAIL_PENGAJUAN_PPBJ = "DETAIL_PENGAJUAN_PPBJ";
export const DETAIL_PENGAJUAN_PTUM = "DETAIL_PENGAJUAN_PTUM";
export const DETAIL_PENGAJUAN_BKKB = "DETAIL_PENGAJUAN_BKKB";
export const DETAIL_PENGAJUAN_BTKB = "DETAIL_PENGAJUAN_BTKB";
export const DETAIL_PENGAJUAN_BJ = "DETAIL_PENGAJUAN_BJ";
export const VALIDATION_ERROR = "VALIDATION_ERROR";
export const ALL_UNIT = "ALL_UNIT";