import {
    ALL_ENTITAS,
    DATA_UPDATED,
    VALIDATION_ERROR,
    SET_LOADING,
    ENTITAS_UNIT,
    USERS_ENTITAS,
    UNIT_OF_USERS_ENTITAS,
    USERS_ENTITAS_UPDATED,
} from ".";

const initialState = {
    allEntitas: [],
    dataAdded: "",
    units: [],
    usersEntitas: [],
    unitUsers: [],
    usersEntitasUpdated: "",
    validationError: {},
    isLoading: false,
};

export default function entitasReducer(state = initialState, action) {
    switch (action.type) {
        case ALL_ENTITAS:
            return { ...state, allEntitas: action.payload };
        case DATA_UPDATED:
            return { ...state, dataAdded: action.payload };
        case ENTITAS_UNIT:
            return { ...state, units: action.payload };
        case USERS_ENTITAS:
            return { ...state, usersEntitas: action.payload };
        case USERS_ENTITAS_UPDATED:
            return { ...state, usersEntitasUpdated: action.payload };
        case UNIT_OF_USERS_ENTITAS:
            return { ...state, unitUsers: action.payload };
        case VALIDATION_ERROR:
            return { ...state, validationError: action.payload };
        case SET_LOADING:
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}