import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store from "./stores/store";
import persistor from "./stores/persistStore";
// css assets
import './assets/vendors/@fortawesome/fontawesome-free/css/all.min.css';
import './assets/vendors/ionicons-npm/css/ionicons.min.css';
import './assets/vendors/linearicons-master/dist/web-font/style.min.css';
import './assets/vendors/pixeden-stroke-7-icon-master/pe-icon-7-stroke/dist/pe-icon-7-stroke.min.css';
import './assets/vendors/fancybox/jquery.fancybox.min.css';
import './assets/styles/css/base.min.css';
import './assets/styles/scss/main.scss';
import 'react-toastify/dist/ReactToastify.css';
import "react-datetime/css/react-datetime.css";

import Login from './pages/auth/login';
import RecoverPassword from './pages/auth/recover-password';
import AdminRoute from './components/AdminRoute';
import UnitsRoute from './components/UnitsRoute';
import ApproversRoute from './components/ApproversRoute';
import ExecutiveDirectorRoute from './components/ExecutiveDirectorRoute';
import FoundationPresidentRoute from './components/FoundationPresidentRoute';
import FinanceHeadRoute from './components/FinanceHeadRoute';
import FinanceStaffRoute from './components/FinanceStaffRoute';
import Admin from './pages/admin';
import Units from './pages/units';
import Approvers from './pages/approvers';
import ExecutiveDirector from './pages/executive-director';
import FoundationPresident from './pages/foundation-president';
import FinanceHead from './pages/finance-head';
import FinanceStaff from './pages/finance-staff';
import NotFoundPage from './pages/404';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Switch>
              <Route path="/" exact component={Login} />
              <Route path="/login" component={Login} />
              <Route path="/recover-password" component={RecoverPassword} />
              <AdminRoute path="/admin" component={Admin} />
              <UnitsRoute path="/units" component={Units} />
              <ApproversRoute path="/approvers" component={Approvers} />
              <ExecutiveDirectorRoute path="/executive-director" component={ExecutiveDirector} />
              <FoundationPresidentRoute path="/foundation-president" component={FoundationPresident} />
              <FinanceHeadRoute path="/finance-head" component={FinanceHead} />
              <FinanceStaffRoute path="/finance-staff" component={FinanceStaff} />
              <Route component={NotFoundPage} />
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
