export const SET_LOADING = "SET_LOADING";
export const SET_ANOTHER_LOADING = "SET_ANOTHER_LOADING";
export const SET_OTHER_LOADING = "SET_OTHER_LOADING";
export const VALIDATION_ERROR = "VALIDATION_ERROR";
export const DATA_POSISI_KEUANGAN_ALL = "DATA_POSISI_KEUANGAN_ALL";
export const DATA_PENGHASILAN_KOMPREHENSIF_ALL = "DATA_PENGHASILAN_KOMPREHENSIF_ALL";
export const DATA_DAFTAR_JURNAL_ALL = "DATA_DAFTAR_JURNAL_ALL";
export const DATA_NERACA_LAJUR_ALL = "DATA_NERACA_LAJUR_ALL";
export const DATA_BUKU_BESAR_ALL = "DATA_BUKU_BESAR_ALL";
export const DATA_POSISI_KEUANGAN_ENTITAS = "DATA_POSISI_KEUANGAN_ENTITAS";
export const DATA_PENGHASILAN_KOMPREHENSIF_ENTITAS = "DATA_PENGHASILAN_KOMPREHENSIF_ENTITAS";
export const DATA_DAFTAR_JURNAL_ENTITAS = "DATA_DAFTAR_JURNAL_ENTITAS";
export const DATA_NERACA_LAJUR_ENTITAS = "DATA_NERACA_LAJUR_ENTITAS";
export const DATA_BUKU_BESAR_ENTITAS = "DATA_BUKU_BESAR_ENTITAS";
