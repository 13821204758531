import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { sendResetPasswordEmail } from "../../stores/userInfo/userAction";
import { Routes } from '../../routes';

const RecoverPassword = () => {
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Email tidak valid")
                .required("Email wajib diisi")
        }),
        onSubmit: (values, actions) => {
            const input = {
                email: values.email
            };
            dispatch(sendResetPasswordEmail(input, actions));
        },
    });

    return (<>
        <div className="app-container app-theme-white body-tabs-shadow">
            <div className="app-container">
                <div className="h-100">
                    <div className="h-100 no-gutters row">
                        <div className="d-none d-lg-block col-lg-4">
                            <div className="slider-light">
                                <div className="slick-slider">
                                    <div>
                                        <div className="position-relative h-100 d-flex justify-content-center align-items-center" tabIndex="-1">
                                            <div className="slide-img-bg" style={{ backgroundImage: 'url("/assets/images/flat-lay-business-concept_53876-64851/flat-lay-business-concept_53876-64851@2x.png")', opacity: '1' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-8">
                            <div className="mx-auto app-login-box col-sm-12 col-md-10 col-lg-9">
                                <div className="d-flex align-items-center">
                                    <img src="/assets/images/Group-16.svg" alt="Logo" className="img-fluid" width="80" />
                                    <h3 className="d-inline-block ml-3">Sistem Informasi<br />Keuangan Yayasan Hasnur Centre</h3>
                                </div>
                                <h4 className="mb-0 mt-4">
                                    <span className="d-block">Lupa Password?</span>
                                    <span>Gunakan form dibawah untuk memulihkan password.</span>
                                </h4>
                                <div className="divider row"></div>
                                <form onSubmit={formik.handleSubmit} noValidate>
                                    <div className="row">
                                        <div className="col-md-6 form-group">
                                            <label>Email</label>
                                            <input
                                                type="email"
                                                name="email"
                                                className={formik.errors['email'] && formik.touched['email'] ? 'form-control is-invalid' : 'form-control'}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                disabled={formik.isDisabled}
                                                value={formik.values.email}
                                            />
                                            <em className={formik.errors['email'] && formik.touched['email'] ? `error invalid-feedback` : `d-none`}>{formik.errors['email']}</em>
                                        </div>
                                        <div className="col-md-6 form-group d-flex align-items-end pb-1">
                                            <div className='full-width'>
                                                <button type="submit" className="btn btn-primary float-left" disabled={formik.isSubmitting}>
                                                    {formik.isSubmitting ? <img src="/assets/images/ajaxloader.gif" className="img-fluid mr-1" style={{width: '18px'}} alt="" /> : <i className="fas fa-sign-in-alt mr-1"></i>}
                                                    Recover Password
                                                </button>
                                                <Link to={Routes.Signin.path} className="btn btn-link float-right">Sign In</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default RecoverPassword;
