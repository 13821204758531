export const Routes = {
    Signin: { path: "/" },
    RecoverPassword: { path: "/recover-password" },

    // Admin
    DashboardAdmin: { path: "/admin/dashboard" },
    EntitasAdmin: { path: "/admin/entitas" },
    PenggunaAdmin: { path: "/admin/pengguna" },
    KodeBukuBesarAdmin: { path: "/admin/kode-buku-besar" },
    KodeAkun2: { path: "/admin/kode-buku-besar/akun-lv2/:id" },
    KodeAkun3: { path: "/admin/kode-buku-besar/akun-lv3/:id1/:id2" },
    KodeAkun4: { path: "/admin/kode-buku-besar/akun-lv4/:id1/:id2/:id3" },
    KodeAkun5: { path: "/admin/kode-buku-besar/akun-lv5/:id1/:id2/:id3/:id4" },
    KodePusatBiaya2: { path: "/admin/kode-buku-besar/pusat-biaya-lv2/:id" },
    KodePusatBiaya3: { path: "/admin/kode-buku-besar/pusat-biaya-lv3/:id1/:id2" },
    KodeProgram2: { path: "/admin/kode-buku-besar/program-lv2/:id" },
    KodeKas2: { path: "/admin/kode-buku-besar/kas-lv2/:id" },
    KodeKas3: { path: "/admin/kode-buku-besar/kas-lv3/:id1/:id2" },
    VoucherAdmin: { path: "/admin/voucher" },
    AnggaranAdmin: { path: "/admin/anggaran" },
    AnggaranReportAdmin: { path: "/admin/anggaran-report/:tahun" },
    AnggaranTahunanAdmin: { path: "/admin/anggaran-tahunan/:entitas_id/:unit/:tahun" },
    AnggaranBulananAdmin: { path: "/admin/anggaran-bulanan/:entitas_id/:unit/:tahun/:bulan" },
    WorkflowAdmin: { path: "/admin/workflow" },
    WorkflowFormAdmin: { path: "/admin/workflow-form/:id?" },
    LaporanKeuanganKomprehensifAdmin: { path: "/admin/laporan-keuangan/komprehensif" },
    LaporanPosisiKeuanganAdmin: { path: "/admin/laporan-keuangan/posisi-keuangan" },
    LaporanDaftarJurnalAdmin: { path: "/admin/laporan-keuangan/daftar-jurnal" },
    LaporanNeracaLajurAdmin: { path: "/admin/laporan-keuangan/neraca-lajur" },
    LaporanBukuBesarAdmin: { path: "/admin/laporan-keuangan/buku-besar" },

    // Units
    DashboardUnits: { path: "/units/dashboard" },
    AnggaranUnits: { path: "/units/anggaran" },
    AnggaranTahunanUnits: { path: "/units/anggaran-tahunan/:tahun" },
    AnggaranBulananUnits: { path: "/units/anggaran-bulanan/:tahun/:bulan" },
    FormulirAnggaranUnits: { path: "/units/formulir-anggaran/:tahun/:bulan/:id?" },
    FormKasBankUnits: { path: "/units/entri-transaksi" },
    PPBJListUnits: { path: "/units/entri-transaksi/ppbj-list" },
    PPBJFormUnits: { path: "/units/entri-transaksi/ppbj-form/:id?" },
    PPBJDetailUnits: { path: "/units/entri-transaksi/ppbj-detail/:id" },
    PPListUnits: { path: "/units/entri-transaksi/pp-list" },
    PPFormUnits: { path: "/units/entri-transaksi/pp-form" },
    PUMListUnits: { path: "/units/entri-transaksi/pum-list" },
    PUMFormUnits: { path: "/units/entri-transaksi/pum-form" },
    PTUMListUnits: { path: "/units/entri-transaksi/ptum-list" },
    PTUMFormUnits: { path: "/units/entri-transaksi/ptum-form/:pum_id/:id?" },
    PTUMDetailUnits: { path: "/units/entri-transaksi/ptum-detail/:id" },
    POListUnits: { path: "/units/entri-transaksi/po-list" },
    POFormUnits: { path: "/units/entri-transaksi/po-form" },
    BKKBListUnits: { path: "/units/entri-transaksi/bkkb-list" },
    BKKBFormUnits: { path: "/units/entri-transaksi/bkkb-form" },
    BJListUnits: { path: "/units/entri-transaksi/bj-list" },
    BJFormUnits: { path: "/units/entri-transaksi/bj-form" },
    BTKBListUnits: { path: "/units/entri-transaksi/btkb-list" },
    BTKBFormUnits: { path: "/units/entri-transaksi/btkb-form" },
    ValidasiPengajuanUnits: { path: "/units/approver/validasi-pengajuan" },
    DetailPengajuanUnits: { path: "/units/approver/detail-pengajuan/:id/:type" },
    RiwayatPengajuanUnits: { path: "/units/riwayat-pengajuan" },
    RiwayatPengajuanDetailPpbjUnits: { path: "/units/riwayat-pengajuan-detail-ppbj/:id/:type" },
    RiwayatPengajuanDetailPtumUnits: { path: "/units/riwayat-pengajuan-detail-ptum/:id/:type" },
    RiwayatPengajuanKeuanganUnits: { path: "/units/riwayat-pengajuan-finance" },
    RiwayatPengajuanDetailKeuanganUnits: { path: "/units/riwayat-pengajuan-detail-finance/:id/:type" },

    // Approvers
    DashboardApprovers: { path: "/approvers/dashboard" },
    ValidasiPengajuanApprovers: { path: "/approvers/validasi-pengajuan" },
    DetailPengajuanApprovers: { path: "/approvers/detail-pengajuan/:id/:type" },
    ValidasiPengajuanKeuanganApprovers: { path: "/approvers/validasi-pengajuan-finance" },
    DetailPengajuanKeuanganApprovers: { path: "/approvers/detail-pengajuan-finance/:id/:type" },
    RiwayatPengajuanApprovers: { path: "/approvers/riwayat-pengajuan" },
    RiwayatPengajuanDetailPpbjApprovers: { path: "/approvers/riwayat-pengajuan-detail-ppbj/:id/:type" },
    RiwayatPengajuanDetailPtumApprovers: { path: "/approvers/riwayat-pengajuan-detail-ptum/:id/:type" },
    RiwayatPengajuanKeuanganApprovers: { path: "/approvers/riwayat-pengajuan-finance" },
    RiwayatPengajuanDetailKeuanganApprovers: { path: "/approvers/riwayat-pengajuan-detail-finance/:id/:type" },

    // Executive Director
    DashboardExecutiveDirector: { path: "/executive-director/dashboard" },
    ValidasiPengajuanExecutiveDirector: { path: "/executive-director/approver/validasi-pengajuan" },
    DetailPengajuanExecutiveDirector: { path: "/executive-director/approver/detail-pengajuan/:id/:type" },
    ValidasiPengajuanKeuanganExecutiveDirector: { path: "/executive-director/approver/validasi-pengajuan-finance" },
    DetailPengajuanKeuanganExecutiveDirector: { path: "/executive-director/approver/detail-pengajuan-finance/:id/:type" },
    RiwayatPengajuanExecutiveDirector: { path: "/executive-director/riwayat-pengajuan" },
    RiwayatPengajuanDetailPpbjExecutiveDirector: { path: "/executive-director/riwayat-pengajuan-detail-ppbj/:id/:type" },
    RiwayatPengajuanDetailPtumExecutiveDirector: { path: "/executive-director/riwayat-pengajuan-detail-ptum/:id/:type" },
    RiwayatPengajuanKeuanganExecutiveDirector: { path: "/executive-director/riwayat-pengajuan-finance" },
    RiwayatPengajuanDetailKeuanganExecutiveDirector: { path: "/executive-director/riwayat-pengajuan-detail-finance/:id/:type" },

    // Foundation President
    DashboardFoundationPresident: { path: "/foundation-president/dashboard" },
    ValidasiPengajuanFoundationPresident: { path: "/foundation-president/approver/validasi-pengajuan" },
    DetailPengajuanFoundationPresident: { path: "/foundation-president/approver/detail-pengajuan/:id/:type" },
    ValidasiPengajuanKeuanganFoundationPresident: { path: "/foundation-president/approver/validasi-pengajuan-finance" },
    DetailPengajuanKeuanganFoundationPresident: { path: "/foundation-president/approver/detail-pengajuan-finance/:id/:type" },
    RiwayatPengajuanFoundationPresident: { path: "/foundation-president/riwayat-pengajuan" },
    RiwayatPengajuanDetailPpbjFoundationPresident: { path: "/foundation-president/riwayat-pengajuan-detail-ppbj/:id/:type" },
    RiwayatPengajuanDetailPtumFoundationPresident: { path: "/foundation-president/riwayat-pengajuan-detail-ptum/:id/:type" },
    RiwayatPengajuanKeuanganFoundationPresident: { path: "/foundation-president/riwayat-pengajuan-finance" },
    RiwayatPengajuanDetailKeuanganFoundationPresident: { path: "/foundation-president/riwayat-pengajuan-detail-finance/:id/:type" },

    // Finance Head
    DashboardFinanceHead: { path: "/finance-head/dashboard" },
    ValidasiPengajuanFinanceHead: { path: "/finance-head/approver/validasi-pengajuan" },
    DetailPengajuanFinanceHead: { path: "/finance-head/approver/detail-pengajuan/:id/:type" },
    ValidasiPengajuanKeuanganFinanceHead: { path: "/finance-head/approver/validasi-pengajuan-finance" },
    DetailPengajuanKeuanganFinanceHead: { path: "/finance-head/approver/detail-pengajuan-finance/:id/:type" },
    RiwayatPengajuanFinanceHead: { path: "/finance-head/riwayat-pengajuan" },
    RiwayatPengajuanDetailPpbjFinanceHead: { path: "/finance-head/riwayat-pengajuan-detail-ppbj/:id/:type" },
    RiwayatPengajuanDetailPtumFinanceHead: { path: "/finance-head/riwayat-pengajuan-detail-ptum/:id/:type" },
    RiwayatPengajuanKeuanganFinanceHead: { path: "/finance-head/riwayat-pengajuan-finance" },
    RiwayatPengajuanDetailKeuanganFinanceHead: { path: "/finance-head/riwayat-pengajuan-detail-finance/:id/:type" },
    LaporanKeuanganKomprehensifFinanceHead: { path: "/finance-head/laporan-keuangan/komprehensif" },
    LaporanPosisiKeuanganFinanceHead: { path: "/finance-head/laporan-keuangan/posisi-keuangan" },
    LaporanDaftarJurnalFinanceHead: { path: "/finance-head/laporan-keuangan/daftar-jurnal" },
    LaporanNeracaLajurFinanceHead: { path: "/finance-head/laporan-keuangan/neraca-lajur" },
    LaporanBukuBesarFinanceHead: { path: "/finance-head/laporan-keuangan/buku-besar" },

    // Finance Staff
    DashboardFinanceStaff: { path: "/finance-staff/dashboard" },
    PengajuanDanaFinanceStaff: { path: "/finance-staff/pengajuan-dana" },
    ValidasiPengajuanFinanceStaff: { path: "/finance-staff/approver/validasi-pengajuan" },
    DetailPengajuanFinanceStaff: { path: "/finance-staff/approver/detail-pengajuan/:id/:type" },
    ValidasiPengajuanKeuanganFinanceStaff: { path: "/finance-staff/approver/validasi-pengajuan-finance" },
    DetailPengajuanKeuanganFinanceStaff: { path: "/finance-staff/approver/detail-pengajuan-finance/:id/:type" },
    POListFinanceStaff: { path: "/finance-staff/pengajuan-dana/po-list" },
    POFormFinanceStaff: { path: "/finance-staff/pengajuan-dana/po-form/:id?" },
    PODetailFinanceStaff: { path: "/finance-staff/pengajuan-dana/po-detail/:id" },
    BKKBListFinanceStaff: { path: "/finance-staff/pengajuan-dana/bkkb-list" },
    BKKBFormFinanceStaff: { path: "/finance-staff/pengajuan-dana/bkkb-form/:id?" },
    BKKBDetailFinanceStaff: { path: "/finance-staff/pengajuan-dana/bkkb-detail/:id" },
    BJListFinanceStaff: { path: "/finance-staff/pengajuan-dana/bj-list" },
    BJFormFinanceStaff: { path: "/finance-staff/pengajuan-dana/bj-form/:id?" },
    BJDetailFinanceStaff: { path: "/finance-staff/pengajuan-dana/bj-detail/:id" },
    BTKBListFinanceStaff: { path: "/finance-staff/pengajuan-dana/btkb-list" },
    BTKBFormFinanceStaff: { path: "/finance-staff/pengajuan-dana/btkb-form/:id?" },
    BTKBDetailFinanceStaff: { path: "/finance-staff/pengajuan-dana/btkb-detail/:id" },
    RiwayatPengajuanFinanceStaff: { path: "/finance-staff/riwayat-pengajuan" },
    RiwayatPengajuanDetailPpbjFinanceStaff: { path: "/finance-staff/riwayat-pengajuan-detail-ppbj/:id/:type" },
    RiwayatPengajuanDetailPtumFinanceStaff: { path: "/finance-staff/riwayat-pengajuan-detail-ptum/:id/:type" },
    RiwayatPengajuanKeuanganFinanceStaff: { path: "/finance-staff/riwayat-pengajuan-finance" },RiwayatPengajuanDetailKeuanganFinanceStaff: { path: "/finance-staff/riwayat-pengajuan-detail-finance/:id/:type" },
    LaporanKeuanganKomprehensifFinanceStaff: { path: "/finance-staff/laporan-keuangan/komprehensif" },
    LaporanPosisiKeuanganFinanceStaff: { path: "/finance-staff/laporan-keuangan/posisi-keuangan" },
    LaporanDaftarJurnalFinanceStaff: { path: "/finance-staff/laporan-keuangan/daftar-jurnal" },
    LaporanNeracaLajurFinanceStaff: { path: "/finance-staff/laporan-keuangan/neraca-lajur" },
    LaporanBukuBesarFinanceStaff: { path: "/finance-staff/laporan-keuangan/buku-besar" },
};
