import React, { lazy, Suspense, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import { Routes } from '../../routes';

import Loader from '../../components/Loader';
const load = (Component) => (props) => (
    <Suspense fallback={<Loader />}>
        <Component {...props} />
    </Suspense>
);

const Header = load(lazy(() => import('../../layouts/admin/header')));
const Sidebar = load(lazy(() => import('../../layouts/admin/sidebar')));
const Footer = load(lazy(() => import('../../layouts/admin/footer')));
const Assets = load(lazy(() => import('../../layouts/admin/assets')));
const AdminNotFound = load(lazy(() => import('./404-admin')));
const Dashboard = load(lazy(() => import('./dashboard')));
const Anggaran = load(lazy(() => import('./anggaran/anggaran')));
const AnggaranReport = load(lazy(() => import('./anggaran/anggaran-report')));
const AnggaranTahunan = load(lazy(() => import('./anggaran/anggaran-tahunan')));
const AnggaranBulanan = load(lazy(() => import('./anggaran/anggaran-bulanan')));
const FormRealisasiPrognosa = load(lazy(() => import('./anggaran/form-realisasi-prognosa')));
const LaporanPosisiKeuangan = load(lazy(() => import('./laporan/posisi-keuangan')));
const LaporanKomprehensif = load(lazy(() => import('./laporan/laporan-komprehensif')));
const LaporanDaftarJurnal = load(lazy(() => import('./laporan/laporan-daftar-jurnal')));
const LaporanNeracaLajur = load(lazy(() => import('./laporan/laporan-neraca-lajur')));
const LaporanBukuBesar = load(lazy(() => import('./laporan/laporan-buku-besar')));
const KodeBukuBesar = load(lazy(() => import('./kode-buku-besar/kodeBukuBesar')));
const Entitas = load(lazy(() => import('./entitas/entitas')));
const EntitasModal = load(lazy(() => import('./entitas/entitasModal')));
const UserEntitasModal = load(lazy(() => import('./entitas/userEntitasModal')));
const Voucher = load(lazy(() => import('./voucher/voucher')));
const KodeAkun2 = load(lazy(() => import('./kode-buku-besar/kode-akun/kodeAkun2')));
const KodeAkun3 = load(lazy(() => import('./kode-buku-besar/kode-akun/kodeAkun3')));
const KodeAkun4 = load(lazy(() => import('./kode-buku-besar/kode-akun/kodeAkun4')));
const KodeAkun5 = load(lazy(() => import('./kode-buku-besar/kode-akun/kodeAkun5')));
const KodePusatBiaya2 = load(lazy(() => import('./kode-buku-besar/kode-pusat-biaya/kodePusatBiaya2')));
const KodePusatBiaya3 = load(lazy(() => import('./kode-buku-besar/kode-pusat-biaya/kodePusatBiaya3')));
const KodeProgram2 = load(lazy(() => import('./kode-buku-besar/kode-program/kodeProgram2')));
const KodeKas2 = load(lazy(() => import('./kode-buku-besar/kode-kas/kodeKas2')));
const KodeKas3 = load(lazy(() => import('./kode-buku-besar/kode-kas/kodeKas3')));
const KodeDanaModal = load(lazy(() => import('./kode-buku-besar/kode-dana/kodeDanaModal')));
const KodeAkunModal1 = load(lazy(() => import('./kode-buku-besar/kode-akun/kodeAkunModal1')));
const KodeAkunModal2 = load(lazy(() => import('./kode-buku-besar/kode-akun/kodeAkunModal2')));
const KodeAkunModal3 = load(lazy(() => import('./kode-buku-besar/kode-akun/kodeAkunModal3')));
const KodeAkunModal4 = load(lazy(() => import('./kode-buku-besar/kode-akun/kodeAkunModal4')));
const KodeAkunModal5 = load(lazy(() => import('./kode-buku-besar/kode-akun/kodeAkunModal5')));
const KodeAkunCcModal = load(lazy(() => import('./kode-buku-besar/kode-akun/kodeAkunCcModal')));
const KodePusatBiayaModal1 = load(lazy(() => import('./kode-buku-besar/kode-pusat-biaya/kodePusatBiayaModal1')));
const KodePusatBiayaModal2 = load(lazy(() => import('./kode-buku-besar/kode-pusat-biaya/kodePusatBiayaModal2')));
const KodePusatBiayaModal3 = load(lazy(() => import('./kode-buku-besar/kode-pusat-biaya/kodePusatBiayaModal3')));
const UserPusatBiayaModal = load(lazy(() => import('./kode-buku-besar/kode-pusat-biaya/userPusatBiayaModal')));
const KodeProgramModal1 = load(lazy(() => import('./kode-buku-besar/kode-program/kodeProgramModal1')));
const KodeProgramModal2 = load(lazy(() => import('./kode-buku-besar/kode-program/kodeProgramModal2')));
const KodeKasModal1 = load(lazy(() => import('./kode-buku-besar/kode-kas/kodeKasModal1')));
const KodeKasModal2 = load(lazy(() => import('./kode-buku-besar/kode-kas/kodeKasModal2')));
const KodeKasModal3 = load(lazy(() => import('./kode-buku-besar/kode-kas/kodeKasModal3')));
const JenisTransaksiModal = load(lazy(() => import('./voucher/jenisTransaksiModal')));
const IdentifikasiTransaksiModal = load(lazy(() => import('./voucher/identifikasiTransaksiModal')));
const NotesModal = load(lazy(() => import('./anggaran/notes-modal')));
const Workflow = load(lazy(() => import('./workflow-transaksi/workflow')));
const WorkflowForm = load(lazy(() => import('./workflow-transaksi/workflow-form')));

const Admin = () => {
    const [voucherData1, setVoucherData1] = useState(null);
    const [voucherData2, setVoucherData2] = useState(null);
    const [entitasData, setEntitasData] = useState(null);
    const [usersEntitas, setUsersEntitas] = useState(null);
    const [kodeDana, setKodeDana] = useState(null);
    const [kodeAkun1, setKodeAkun1] = useState(null);
    const [kodeAkun2, setKodeAkun2] = useState(null);
    const [kodeAkun3, setKodeAkun3] = useState(null);
    const [kodeAkun4, setKodeAkun4] = useState(null);
    const [kodeAkun5, setKodeAkun5] = useState(null);
    const [kodeAkunCc, setkodeAkunCc] = useState(null);
    const [kodePusatBiaya1, setKodePusatBiaya1] = useState(null);
    const [kodePusatBiaya2, setKodePusatBiaya2] = useState(null);
    const [kodePusatBiaya3, setKodePusatBiaya3] = useState(null);
    const [userPusatBiaya, setUserPusatBiaya] = useState(null);
    const [kodeProgram1, setKodeProgram1] = useState(null);
    const [kodeProgram2, setKodeProgram2] = useState(null);
    const [kodeKas1, setKodeKas1] = useState(null);
    const [kodeKas2, setKodeKas2] = useState(null);
    const [kodeKas3, setKodeKas3] = useState(null);
    const [note, setNote] = useState(null);
    const [formRealisasiPrognosa, setFormRealisasiPrognosa] = useState([]);

    return (<>
        <div className="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
            <Header />
            <div className="app-main">
                <Sidebar />
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <Switch>
                            <Route
                                path={Routes.DashboardAdmin.path}
                                render={props => <Dashboard {...props} />}
                            />
                            <Route
                                path={Routes.AnggaranAdmin.path}
                                render={props => <Anggaran {...props} />}
                            />
                            <Route
                                path={Routes.AnggaranReportAdmin.path}
                                render={props => <AnggaranReport {...props} />}
                            />
                            <Route
                                path={Routes.AnggaranTahunanAdmin.path}
                                render={props => <AnggaranTahunan {...props}
                                    noteData={(val) => setNote(val)} />}
                            />
                            <Route
                                path={Routes.AnggaranBulananAdmin.path}
                                render={props => <AnggaranBulanan {...props}
                                    noteData={(val) => setNote(val)}
                                    formRealisasiPrognosaData={(val) => setFormRealisasiPrognosa(val)} />}
                            />
                            <Route
                                path={Routes.LaporanPosisiKeuanganAdmin.path}
                                render={props => <LaporanPosisiKeuangan {...props} />}
                            />
                            <Route
                                path={Routes.LaporanKeuanganKomprehensifAdmin.path}
                                render={props => <LaporanKomprehensif {...props} />}
                            />
                            <Route
                                path={Routes.LaporanDaftarJurnalAdmin.path}
                                render={props => <LaporanDaftarJurnal {...props} />}
                            />
                            <Route
                                path={Routes.LaporanNeracaLajurAdmin.path}
                                render={props => <LaporanNeracaLajur {...props} />}
                            />
                            <Route
                                path={Routes.LaporanBukuBesarAdmin.path}
                                render={props => <LaporanBukuBesar {...props} />}
                            />
                            <Route
                                path={Routes.EntitasAdmin.path}
                                render={props => <Entitas {...props}
                                    entitasData={(val) => setEntitasData(val)}
                                    usersEntitas={(val) => setUsersEntitas(val)} />}
                            />
                            <Route
                                path={Routes.KodeBukuBesarAdmin.path}
                                exact
                                render={props => <KodeBukuBesar {...props}
                                    kodeDanaData={(val) => setKodeDana(val)}
                                    kodeAkunData={(val) => setKodeAkun1(val)}
                                    kodePusatBiayaData={(val) => setKodePusatBiaya1(val)}
                                    kodeProgramData={(val) => setKodeProgram1(val)}
                                    kodeKasData={(val) => setKodeKas1(val)} />}
                            />
                            <Route
                                path={Routes.KodeAkun2.path}
                                render={props => <KodeAkun2 {...props}
                                    kodeAkunData={(val) => setKodeAkun2(val)} />}
                            />
                            <Route
                                path={Routes.KodeAkun3.path}
                                render={props => <KodeAkun3 {...props}
                                    kodeAkunData={(val) => setKodeAkun3(val)} />}
                            />
                            <Route
                                path={Routes.KodeAkun4.path}
                                render={props => <KodeAkun4 {...props}
                                    kodeAkunData={(val) => setKodeAkun4(val)} />}
                            />
                            <Route
                                path={Routes.KodeAkun5.path}
                                render={props => <KodeAkun5 {...props}
                                    kodeAkunData={(val) => setKodeAkun5(val)}
                                    kodeAkunCc={(val) => setkodeAkunCc(val)} />}
                            />
                            <Route
                                path={Routes.KodePusatBiaya2.path}
                                render={props => <KodePusatBiaya2 {...props}
                                    kodePusatBiayaData={(val) => setKodePusatBiaya2(val)} />}
                            />
                            <Route
                                path={Routes.KodePusatBiaya3.path}
                                render={props => <KodePusatBiaya3 {...props}
                                    kodePusatBiayaData={(val) => setKodePusatBiaya3(val)}
                                    userPusatBiayaData={(val) => setUserPusatBiaya(val)} />}
                            />
                            <Route
                                path={Routes.KodeProgram2.path}
                                render={props => <KodeProgram2 {...props}
                                    kodeProgramData={(val) => setKodeProgram2(val)} />}
                            />
                            <Route
                                path={Routes.KodeKas2.path}
                                render={props => <KodeKas2 {...props}
                                    kodeKasData={(val) => setKodeKas2(val)} />}
                            />
                            <Route
                                path={Routes.KodeKas3.path}
                                render={props => <KodeKas3 {...props}
                                    kodeKasData={(val) => setKodeKas3(val)} />}
                            />
                            <Route
                                path={Routes.VoucherAdmin.path}
                                render={props => <Voucher {...props}
                                    voucherData1={(val) => setVoucherData1(val)}
                                    voucherData2={(val) => setVoucherData2(val)} />}
                            />
                            <Route
                                path={Routes.WorkflowAdmin.path}
                                render={props => <Workflow {...props} />}
                            />
                            <Route
                                path={Routes.WorkflowFormAdmin.path}
                                render={props => <WorkflowForm {...props} />}
                            />
                            <Route render={() => <AdminNotFound />} />
                        </Switch>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>

        <EntitasModal data={entitasData} />
        <UserEntitasModal data={usersEntitas} />
        <KodeDanaModal data={kodeDana} />
        <KodeAkunModal1 data={kodeAkun1} />
        <KodeAkunModal2 data={kodeAkun2} />
        <KodeAkunModal3 data={kodeAkun3} />
        <KodeAkunModal4 data={kodeAkun4} />
        <KodeAkunModal5 data={kodeAkun5} />
        <KodeAkunCcModal data={kodeAkunCc} />
        <KodePusatBiayaModal1 data={kodePusatBiaya1} />
        <KodePusatBiayaModal2 data={kodePusatBiaya2} />
        <KodePusatBiayaModal3 data={kodePusatBiaya3} />
        <UserPusatBiayaModal data={userPusatBiaya} />
        <KodeProgramModal1 data={kodeProgram1} />
        <KodeProgramModal2 data={kodeProgram2} />
        <KodeKasModal1 data={kodeKas1} />
        <KodeKasModal2 data={kodeKas2} />
        <KodeKasModal3 data={kodeKas3} />
        <JenisTransaksiModal data={voucherData1} />
        <IdentifikasiTransaksiModal data={voucherData2} />
        <NotesModal data={note} />
        <FormRealisasiPrognosa data={formRealisasiPrognosa} />

        <ToastContainer />
        <Assets />
    </>);
}
 
export default Admin;
