export const SET_LOADING_COST_CENTRE = "SET_LOADING_COST_CENTRE";
export const SET_LOADING_PROGRAM = "SET_LOADING_PROGRAM";
export const SET_LOADING_DANA = "SET_LOADING_DANA";
export const SET_LOADING_BUDGET = "SET_LOADING_BUDGET";
export const SET_LOADING_KAS = "SET_LOADING_KAS";
export const SET_LOADING_UNIT = "SET_LOADING_UNIT";
export const KODE_COST_CENTRE = "KODE_COST_CENTRE";
export const KODE_PROGRAM = "KODE_PROGRAM";
export const KODE_DANA = "KODE_DANA";
export const KODE_BUDGET = "KODE_BUDGET";
export const KODE_KAS = "KODE_KAS";
export const DETAIL_PPBJ_ADDED = "DETAIL_PPBJ_ADDED";
export const DATA_UPDATED = "DATA_UPDATED";
export const VALIDATION_ERROR = "VALIDATION_ERROR";
export const SET_LOADING = "SET_LOADING";
export const SET_ANOTHER_LOADING = "SET_ANOTHER_LOADING";
export const LIST_PPBJ = "LIST_PPBJ";
export const DATA_EDIT_PPBJ = "DATA_EDIT_PPBJ";
export const DETAIL_PPBJ = "DETAIL_PPBJ";
export const SUBMIT_PPBJ = "SUBMIT_PPBJ";
export const APPROVAL_HISTORY = "APPROVAL_HISTORY";
export const ALL_APPROVER = "ALL_APPROVER";
export const ALL_APPROVER_DATA = "ALL_APPROVER_DATA";
export const ALL_USERS_UNIT = "ALL_USERS_UNIT";
export const ALL_USERS_UNIT_DATA = "ALL_USERS_UNIT_DATA";
export const LIST_PUM_APPROVED = "LIST_PUM_APPROVED";
export const DETAIL_PUM = "DETAIL_PUM";
export const LIST_PTUM = "LIST_PTUM";
export const DETAIL_PTUM_ADDED = "DETAIL_PTUM_ADDED";
export const SUBMIT_PTUM= "SUBMIT_PTUM";
export const DATA_EDIT_PTUM = "DATA_EDIT_PTUM";
export const DETAIL_PTUM = "DETAIL_PTUM";
export const LIST_PO = "LIST_PO";
export const DETAIL_PO = "DETAIL_PO";
export const DETAIL_PO_ADDED = "DETAIL_PO_ADDED";
export const DATA_EDIT_PO = "DATA_EDIT_PO";
export const PPBJ_APPROVED_WITH_PP = "PPBJ_APPROVED_WITH_PP";
export const LIST_BKKB = "LIST_BKKB";
export const LIST_PPBJ_APPROVED = "LIST_PPBJ_APPROVED";
export const DETAIL_BKKB = "DETAIL_BKKB";
export const DETAIL_BKKB_ADDED = "DETAIL_BKKB_ADDED";
export const DATA_EDIT_BKKB = "DATA_EDIT_BKKB";
export const SUBMIT_BKKB = "SUBMIT_BKKB";
export const ALL_UNIT_CC = "ALL_UNIT_CC";
export const LIST_BTKB = "LIST_BTKB";
export const DETAIL_BTKB = "DETAIL_BTKB";
export const DETAIL_BTKB_ADDED = "DETAIL_BTKB_ADDED";
export const DATA_EDIT_BTKB = "DATA_EDIT_BTKB";
export const SUBMIT_BTKB = "SUBMIT_BTKB";
export const LIST_BJ = "LIST_BJ";
export const DETAIL_BJ = "DETAIL_BJ";
export const DETAIL_BJ_ADDED = "DETAIL_BJ_ADDED";
export const DATA_EDIT_BJ = "DATA_EDIT_BJ";
export const SUBMIT_BJ = "SUBMIT_BJ";