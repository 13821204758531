export const LIST_INSTANSI = "LIST_INSTANSI";
export const SET_INSTANSI = "SET_INSTANSI";
export const ALL_KODE_DANA = "ALL_KODE_DANA";
export const ALL_AKUN1 = "ALL_AKUN1";
export const ALL_AKUN2 = "ALL_AKUN2";
export const ALL_AKUN3 = "ALL_AKUN3";
export const ALL_AKUN4 = "ALL_AKUN4";
export const ALL_AKUN5 = "ALL_AKUN5";
export const ALL_PUSAT_BIAYA1 = "ALL_PUSAT_BIAYA1";
export const ALL_PUSAT_BIAYA2 = "ALL_PUSAT_BIAYA2";
export const ALL_PUSAT_BIAYA3 = "ALL_PUSAT_BIAYA3";
export const ALL_PROGRAM1 = "ALL_PROGRAM1";
export const ALL_PROGRAM2 = "ALL_PROGRAM2";
export const ALL_KAS1 = "ALL_KAS1";
export const ALL_KAS2 = "ALL_KAS2";
export const ALL_KAS3 = "ALL_KAS3";
export const KODE_DANA_UPDATED = "KODE_DANA_UPDATED";
export const KODE_AKUN_UPDATED1 = "KODE_AKUN_UPDATED1";
export const KODE_AKUN_UPDATED2 = "KODE_AKUN_UPDATED2";
export const KODE_AKUN_UPDATED3 = "KODE_AKUN_UPDATED3";
export const KODE_AKUN_UPDATED4 = "KODE_AKUN_UPDATED4";
export const KODE_AKUN_UPDATED5 = "KODE_AKUN_UPDATED5";
export const KODE_PUSAT_BIAYA_UPDATED1 = "KODE_PUSAT_BIAYA_UPDATED1";
export const KODE_PUSAT_BIAYA_UPDATED2 = "KODE_PUSAT_BIAYA_UPDATED2";
export const KODE_PUSAT_BIAYA_UPDATED3 = "KODE_PUSAT_BIAYA_UPDATED3";
export const KODE_PROGRAM_UPDATED1 = "KODE_PROGRAM_UPDATED1";
export const KODE_PROGRAM_UPDATED2 = "KODE_PROGRAM_UPDATED2";
export const KODE_KAS_UPDATED1 = "KODE_KAS_UPDATED1";
export const KODE_KAS_UPDATED2 = "KODE_KAS_UPDATED2";
export const KODE_KAS_UPDATED3 = "KODE_KAS_UPDATED3";
export const USERS_PUSAT_BIAYA = "USERS_PUSAT_BIAYA";
export const USERS_PUSAT_BIAYA_UPDATED = "USERS_PUSAT_BIAYA_UPDATED";
export const KODE_AKUN_CC = "KODE_AKUN_CC";
export const KODE_AKUN_CC_UPDATED = "KODE_AKUN_CC_UPDATED";
export const ALL_KODE_AKUN5 = "ALL_KODE_AKUN5";
export const SET_LOADING = "SET_LOADING";
export const VALIDATION_ERROR = "VALIDATION_ERROR";
export const SET_LOADING_ALL_KODE_AKUN = "SET_LOADING_ALL_KODE_AKUN";
