import {
    SET_LOADING_COST_CENTRE,
    SET_LOADING_PROGRAM,
    SET_LOADING_DANA,
    SET_LOADING_BUDGET,
    SET_LOADING_KAS,
    KODE_COST_CENTRE,
    KODE_PROGRAM,
    KODE_DANA,
    KODE_BUDGET,
    KODE_KAS,
    DETAIL_PPBJ_ADDED,
    VALIDATION_ERROR,
    SET_LOADING,
    SET_ANOTHER_LOADING,
    LIST_PPBJ,
    DATA_UPDATED,
    DATA_EDIT_PPBJ,
    DETAIL_PPBJ,
    SUBMIT_PPBJ,
    APPROVAL_HISTORY,
    ALL_APPROVER,
    ALL_APPROVER_DATA,
    ALL_USERS_UNIT,
    ALL_USERS_UNIT_DATA,
    LIST_PUM_APPROVED,
    DETAIL_PUM,
    DETAIL_PTUM_ADDED,
    DATA_EDIT_PTUM,
    SUBMIT_PTUM,
    LIST_PTUM,
    DETAIL_PTUM,
    LIST_PO,
    DETAIL_PO_ADDED,
    PPBJ_APPROVED_WITH_PP,
    DATA_EDIT_PO,
    DETAIL_PO,
    LIST_BKKB,
    LIST_PPBJ_APPROVED,
    DETAIL_BKKB_ADDED,
    DETAIL_BKKB,
    DATA_EDIT_BKKB,
    SUBMIT_BKKB,
    ALL_UNIT_CC,
    LIST_BTKB,
    DETAIL_BTKB,
    DETAIL_BTKB_ADDED,
    DATA_EDIT_BTKB,
    SUBMIT_BTKB,
    LIST_BJ,
    DATA_EDIT_BJ,
    DETAIL_BJ,
    DETAIL_BJ_ADDED,
    SUBMIT_BJ,
} from ".";

const initialState = {
    kodeCostCentre: [],
    kodeProgram: [],
    kodeDana: [],
    kodeBudget: [],
    kodeAkun: [],
    kodeKas: [],
    isLoadingOptCostCentre: false,
    isLoadingOptProgram: false,
    isLoadingOptDana: false,
    isLoadingOptBudget: false,
    isLoadingOptKas: false,
    detailPpbj: [],
    validationError: {},
    listPpbj: [],
    listPumApproved: [],
    isLoading: false,
    isAnotherLoading: false,
    dataAdded: "",
    dataEditPpbj: [],
    detailPpbjList: {},
    submitPpbj: "",
    approvalHistory: [],
    dataApprovers: [],
    approvers: [],
    users: [],
    dataUsers: [],
    detailPum: {},
    detailPtum: [],
    dataEditPtum: [],
    submitPtum: "",
    listPtum: [],
    detailPtumList: {},
    detailPo: [],
    detailPoList: {},
    ppbjApprovedWithPp: [],
    dataEditPo: [],
    listPo: [],
    listBkkb: [],
    listPpbjApproved: [],
    detailBkkbList: {},
    detailBkkb: [],
    dataEditBkkb: [],
    submitBkkb: "",
    listCcUnit: [],
    listBtkb: [],
    detailBtkbList: {},
    dataEditBtkb: [],
    detailBtkb: [],
    submitBtkb: "",
    listBj: [],
    detailBjList: {},
    detailBj: [],
    dataEditBj: [],
    submitBj: "",
};

export default function entriTransaksiReducer(state = initialState, action) {
    switch (action.type) {
        case SET_LOADING_COST_CENTRE:
            return { ...state, isLoadingOptCostCentre: action.payload };
        case SET_LOADING_PROGRAM:
            return { ...state, isLoadingOptProgram: action.payload };
        case SET_LOADING_DANA:
            return { ...state, isLoadingOptDana: action.payload };
        case SET_LOADING_BUDGET:
            return { ...state, isLoadingOptBudget: action.payload };
        case SET_LOADING_KAS:
            return { ...state, isLoadingOptKas: action.payload };
        case KODE_COST_CENTRE:
            return { ...state, kodeCostCentre: action.payload };
        case KODE_PROGRAM:
            return { ...state, kodeProgram: action.payload };
        case KODE_DANA:
            return { ...state, kodeDana: action.payload };
        case KODE_KAS:
            return { ...state, kodeKas: action.payload };
        case KODE_BUDGET:
            return { ...state, ...action.payload };
        case DETAIL_PPBJ_ADDED:
            return { ...state, detailPpbj: action.payload };
        case VALIDATION_ERROR:
            return { ...state, validationError: action.payload };
        case LIST_PPBJ:
            return { ...state, listPpbj: action.payload };
        case SET_LOADING:
            return { ...state, isLoading: action.payload };
        case SET_ANOTHER_LOADING:
            return { ...state, isAnotherLoading: action.payload };
        case DATA_UPDATED:
            return { ...state, dataAdded: action.payload };
        case DATA_EDIT_PPBJ:
            return { ...state, dataEditPpbj: action.payload };
        case DETAIL_PPBJ:
            return { ...state, detailPpbjList: action.payload };
        case SUBMIT_PPBJ:
            return { ...state, submitPpbj: action.payload };
        case APPROVAL_HISTORY:
            return { ...state, approvalHistory: action.payload };
        case ALL_APPROVER:
            return { ...state, approvers: action.payload };
        case ALL_APPROVER_DATA:
            return { ...state, dataApprovers: action.payload };
        case ALL_USERS_UNIT:
            return { ...state, users: action.payload };
        case ALL_USERS_UNIT_DATA:
            return { ...state, dataUsers: action.payload };
        case LIST_PUM_APPROVED:
            return { ...state, listPumApproved: action.payload };
        case DETAIL_PUM:
            return { ...state, detailPum: action.payload };
        case DETAIL_PTUM_ADDED:
            return { ...state, detailPtum: action.payload };
        case DATA_EDIT_PTUM:
            return { ...state, dataEditPtum: action.payload };
        case SUBMIT_PTUM:
            return { ...state, submitPtum: action.payload };
        case LIST_PTUM:
            return { ...state, listPtum: action.payload };
        case DETAIL_PTUM:
            return { ...state, detailPtumList: action.payload };
        case LIST_PO:
            return { ...state, listPo: action.payload };
        case DETAIL_PO:
            return { ...state, detailPoList: action.payload };
        case DETAIL_PO_ADDED:
            return { ...state, detailPo: action.payload };
        case PPBJ_APPROVED_WITH_PP:
            return { ...state, ppbjApprovedWithPp: action.payload };
        case DATA_EDIT_PO:
            return { ...state, dataEditPo: action.payload };
        case LIST_BKKB:
            return { ...state, listBkkb: action.payload };
        case LIST_PPBJ_APPROVED:
            return { ...state, listPpbjApproved: action.payload };
        case DETAIL_BKKB:
            return { ...state, detailBkkbList: action.payload };
        case DETAIL_BKKB_ADDED:
            return { ...state, detailBkkb: action.payload };
        case DATA_EDIT_BKKB:
            return { ...state, dataEditBkkb: action.payload };
        case SUBMIT_BKKB:
            return { ...state, submitBkkb: action.payload };
        case ALL_UNIT_CC:
            return { ...state, listCcUnit: action.payload };
        case LIST_BTKB:
            return { ...state, listBtkb: action.payload };
        case DETAIL_BTKB:
            return { ...state, detailBtkbList: action.payload };
        case DETAIL_BTKB_ADDED:
            return { ...state, detailBtkb: action.payload };
        case DATA_EDIT_BTKB:
            return { ...state, dataEditBtkb: action.payload };
        case SUBMIT_BTKB:
            return { ...state, submitBtkb: action.payload };
        case LIST_BJ:
            return { ...state, listBj: action.payload };
        case DETAIL_BJ:
            return { ...state, detailBjList: action.payload };
        case DETAIL_BJ_ADDED:
            return { ...state, detailBj: action.payload };
        case DATA_EDIT_BJ:
            return { ...state, dataEditBj: action.payload };
        case SUBMIT_BJ:
            return { ...state, submitBj: action.payload };
        default:
            return state;
    }
}