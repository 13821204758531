import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import { Routes } from '../../routes';

import Loader from '../../components/Loader';
const load = (Component) => (props) => (
    <Suspense fallback={<Loader />}>
        <Component {...props} />
    </Suspense>
);

const Header = load(lazy(() => import('../../layouts/finance-staff/header')));
const Sidebar = load(lazy(() => import('../../layouts/finance-staff/sidebar')));
const Footer = load(lazy(() => import('../../layouts/finance-staff/footer')));
const Assets = load(lazy(() => import('../../layouts/finance-staff/assets')));
const FinanceStaffNotFound = load(lazy(() => import('./404-finance-staff')));
const Dashboard = load(lazy(() => import('./dashboard')));
const PengajuanDana = load(lazy(() => import('./pengajuan-dana/pengajuan-dana-list')));
const POList = load(lazy(() => import('./pengajuan-dana/po/po-list')));
const POForm = load(lazy(() => import('./pengajuan-dana/po/po-form')));
const PODetail = load(lazy(() => import('./pengajuan-dana/po/po-detail')));
const BKKBList = load(lazy(() => import('./pengajuan-dana/bkkb/bkkb-list')));
const BKKBForm = load(lazy(() => import('./pengajuan-dana/bkkb/bkkb-form')));
const BKKBDetail = load(lazy(() => import('./pengajuan-dana/bkkb/bkkb-detail')));
const BJList = load(lazy(() => import('./pengajuan-dana/bj/bj-list')));
const BJForm = load(lazy(() => import('./pengajuan-dana/bj/bj-form')));
const BJDetail = load(lazy(() => import('./pengajuan-dana/bj/bj-detail')));
const BTKBList = load(lazy(() => import('./pengajuan-dana/btkb/btkb-list')));
const BTKBForm = load(lazy(() => import('./pengajuan-dana/btkb/btkb-form')));
const BTKBDetail = load(lazy(() => import('./pengajuan-dana/btkb/btkb-detail')));
const ValidasiPengajuan = load(lazy(() => import('../approvers/validasi-pengajuan/validasi-pengajuan')));
const DetailPengajuan = load(lazy(() => import('../approvers/validasi-pengajuan/detail-pengajuan')));
const RiwayatPengajuan = load(lazy(() => import('../riwayat-pengajuan/unit/riwayat-pengajuan-list')));
const RiwayatPengajuanDetailPpbj = load(lazy(() => import('../riwayat-pengajuan/unit/riwayat-pengajuan-detail-ppbj')));
const RiwayatPengajuanDetailPtum = load(lazy(() => import('../riwayat-pengajuan/unit/riwayat-pengajuan-detail-ptum')));
const ValidasiPengajuanFinance = load(lazy(() => import('../approvers-finance/validasi-pengajuan/validasi-pengajuan')));
const DetailPengajuanFinance = load(lazy(() => import('../approvers-finance/validasi-pengajuan/detail-pengajuan')));
const RiwayatPengajuanFinance = load(lazy(() => import('../riwayat-pengajuan/finance/riwayat-pengajuan-list')));
const RiwayatPengajuanDetailFinanceBkkb = load(lazy(() => import('../riwayat-pengajuan/finance/riwayat-pengajuan-detail-bkkb')));
const RiwayatPengajuanDetailFinanceBtkb = load(lazy(() => import('../riwayat-pengajuan/finance/riwayat-pengajuan-detail-btkb')));
const RiwayatPengajuanDetailFinanceBj = load(lazy(() => import('../riwayat-pengajuan/finance/riwayat-pengajuan-detail-bj')));
const LaporanPosisiKeuangan = load(lazy(() => import('./laporan/posisi-keuangan')));
const LaporanKomprehensif = load(lazy(() => import('./laporan/laporan-komprehensif')));
const LaporanDaftarJurnal = load(lazy(() => import('./laporan/laporan-daftar-jurnal')));
const LaporanNeracaLajur = load(lazy(() => import('./laporan/laporan-neraca-lajur')));
const LaporanBukuBesar = load(lazy(() => import('./laporan/laporan-buku-besar')));

const Index = () => {
    return (<>
        <div className="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
            <Header />
            <div className="app-main">
                <Sidebar />
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <Switch>
                            <Route
                                path={Routes.DashboardFinanceStaff.path}
                                render={props => <Dashboard {...props} />}
                            />
                            <Route
                                exact
                                path={Routes.PengajuanDanaFinanceStaff.path}
                                render={props => <PengajuanDana {...props} />}
                            />
                            <Route
                                path={Routes.ValidasiPengajuanKeuanganFinanceStaff.path}
                                render={props => <ValidasiPengajuanFinance {...props} />}
                            />
                            <Route
                                path={Routes.DetailPengajuanKeuanganFinanceStaff.path}
                                render={props => <DetailPengajuanFinance {...props} />}
                            />
                            <Route
                                path={Routes.POListFinanceStaff.path}
                                render={props => <POList {...props} />}
                            />
                            <Route
                                path={Routes.POFormFinanceStaff.path}
                                render={props => <POForm {...props} />}
                            />
                            <Route
                                path={Routes.PODetailFinanceStaff.path}
                                render={props => <PODetail {...props} />}
                            />
                            <Route
                                path={Routes.BKKBListFinanceStaff.path}
                                render={props => <BKKBList {...props} />}
                            />
                            <Route
                                path={Routes.BKKBFormFinanceStaff.path}
                                render={props => <BKKBForm {...props} />}
                            />
                            <Route
                                path={Routes.BKKBDetailFinanceStaff.path}
                                render={props => <BKKBDetail {...props} />}
                            />
                            <Route
                                path={Routes.BJListFinanceStaff.path}
                                render={props => <BJList {...props} />}
                            />
                            <Route
                                path={Routes.BJFormFinanceStaff.path}
                                render={props => <BJForm {...props} />}
                            />
                            <Route
                                path={Routes.BJDetailFinanceStaff.path}
                                render={props => <BJDetail {...props} />}
                            />
                            <Route
                                path={Routes.BTKBListFinanceStaff.path}
                                render={props => <BTKBList {...props} />}
                            />
                            <Route
                                path={Routes.BTKBFormFinanceStaff.path}
                                render={props => <BTKBForm {...props} />}
                            />
                            <Route
                                path={Routes.BTKBDetailFinanceStaff.path}
                                render={props => <BTKBDetail {...props} />}
                            />
                            <Route
                                path={Routes.ValidasiPengajuanFinanceStaff.path}
                                render={props => <ValidasiPengajuan {...props} />}
                            />
                            <Route
                                path={Routes.DetailPengajuanFinanceStaff.path}
                                render={props => <DetailPengajuan {...props} />}
                            />
                            <Route
                                path={Routes.RiwayatPengajuanFinanceStaff.path}
                                render={props => <RiwayatPengajuan {...props} />}
                            />
                            <Route
                                path={Routes.RiwayatPengajuanDetailPpbjFinanceStaff.path}
                                render={props => <RiwayatPengajuanDetailPpbj {...props} />}
                            />
                            <Route
                                path={Routes.RiwayatPengajuanDetailPtumFinanceStaff.path}
                                render={props => <RiwayatPengajuanDetailPtum {...props} />}
                            />
                            <Route
                                path={Routes.RiwayatPengajuanKeuanganFinanceStaff.path}
                                render={props => <RiwayatPengajuanFinance {...props} />}
                            />
                            <Route
                                path={Routes.RiwayatPengajuanDetailKeuanganFinanceStaff.path.replace(':type', 'bkkb')}
                                render={props => <RiwayatPengajuanDetailFinanceBkkb {...props} />}
                            />
                            <Route
                                path={Routes.RiwayatPengajuanDetailKeuanganFinanceStaff.path.replace(':type', 'btkb')}
                                render={props => <RiwayatPengajuanDetailFinanceBtkb {...props} />}
                            />
                            <Route
                                path={Routes.RiwayatPengajuanDetailKeuanganFinanceStaff.path.replace(':type', 'bj')}
                                render={props => <RiwayatPengajuanDetailFinanceBj {...props} />}
                            />
                            <Route
                                path={Routes.LaporanPosisiKeuanganFinanceStaff.path}
                                render={props => <LaporanPosisiKeuangan {...props} />}
                            />
                            <Route
                                path={Routes.LaporanKeuanganKomprehensifFinanceStaff.path}
                                render={props => <LaporanKomprehensif {...props} />}
                            />
                            <Route
                                path={Routes.LaporanDaftarJurnalFinanceStaff.path}
                                render={props => <LaporanDaftarJurnal {...props} />}
                            />
                            <Route
                                path={Routes.LaporanNeracaLajurFinanceStaff.path}
                                render={props => <LaporanNeracaLajur {...props} />}
                            />
                            <Route
                                path={Routes.LaporanBukuBesarFinanceStaff.path}
                                render={props => <LaporanBukuBesar {...props} />}
                            />
                            <Route render={() => <FinanceStaffNotFound />} />
                        </Switch>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>

        <ToastContainer />
        <Assets />
    </>);
}

export default Index;
