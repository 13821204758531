import Swal from 'sweetalert2';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { Routes } from "../../routes";
import { setAuthorizationHeader } from "../../configs/axios";
import {
    SET_LOGIN,
    SET_LOGOUT,
    SET_TOKEN,
    SET_ENTITAS,
} from ".";

export const userLogout = () => {
    localStorage.clear();
    return {
      type: SET_LOGOUT,
    };
};

export const setLogin = () => {
    return {
      type: SET_LOGIN,
    };
};

export const setToken = (data) => {
    return {
        type: SET_TOKEN,
        payload: data
    }
}

const initData = (dispatch, data) => {
    let payload = {
        nama: data.unit,
        unit_id: data.unit_id,
        user_id: data.id,
    };
    localStorage.setItem('entitas', JSON.stringify(payload));
    dispatch({
        type: SET_ENTITAS,
        payload: payload
    });
}

export const login = (formData, history, actions) => {
    return (dispatch) => {
        const controller = new AbortController();
        axios({
            url: `${process.env.REACT_APP_API_HOST}/users/login`,
            method: "post",
            responseType: 'json',
            headers: {'Accept': 'application/json'},
            signal: controller.signal,
            data: formData,
        })
        .then(async (res) => {
            actions.setSubmitting(false);
            actions.resetForm();

            let data = jwt_decode(res.data.data.token).data;
             // role keuangan unit, approver, staff, kepala keuangan, direktur eksekutif, ketua yayasan
            let roles = [6, 7, 8, 9, 10, 11];
            let userRoles = data.roles.map(d => d[1]).filter(d => roles.includes(d)); // role keuangan
            if (data.type === 'admin' && data.platform === 'keuangan') {
                setAuthorizationHeader(res.data.data.token);
                localStorage.setItem(
                    "token",
                    JSON.stringify({
                        ...res.data.data,
                        email: data.email,
                    })
                );
                localStorage.setItem("isAdmin", true);
                dispatch(setLogin());
                dispatch(setToken({
                    ...res.data.data,
                    email: data.email,
                }));
                history.push(Routes.DashboardAdmin.path);
            } else if (data.type === 'user' && userRoles.length > 0) {
                const _endpoint = `${process.env.REACT_APP_API_HOST}/keuangan/auth/get-user-entitas`;
                const _data = { user_id: data.id };
                const anotherController = new AbortController();
                const _options = {
                    headers: {
                        'Authorization': res.data.data.token,
                        'Accept': 'application/json'
                    },
                    signal: anotherController.signal,
                };
                const getUserEntitas = await axios.post(_endpoint, _data, _options);
                anotherController.abort();
                let inputOptions = {};
                for (const d of getUserEntitas.data) {
                    inputOptions[d.entitas_id] = d.nama;
                }

                setAuthorizationHeader(res.data.data.token);
                localStorage.setItem(
                    "token",
                    JSON.stringify({
                        ...res.data.data,
                        email: data.email,
                    })
                );
                localStorage.setItem("userRoles", JSON.stringify(userRoles));
                dispatch(setLogin());
                dispatch(setToken({
                    ...res.data.data,
                    email: data.email,
                }));
                let payload = {
                    dispatch,
                    history,
                    token: res.data.data.token,
                    user_id: data.id,
                    unit_id: data.unit_id,
                    unit: data.unit
                };

                if (userRoles.includes(6)) { // unit keuangan
                    if (userRoles.includes(7)) {
                        // if has approver role
                        localStorage.setItem("role", "unit");
                    }
                    localStorage.setItem("isUnits", true);
                    await verifyUser({
                        payload,
                        inputOptions,
                        userEntitasAmount: getUserEntitas.data.length,
                        route: Routes.DashboardUnits.path,
                    });
                } else if (userRoles.includes(7) &&
                          !userRoles.includes(8) &&
                          !userRoles.includes(9) &&
                          !userRoles.includes(10) &&
                          !userRoles.includes(11)
                ) { // approver keuangan (only)
                    localStorage.setItem("role", "approver");
                    localStorage.setItem("isApprovers", true);
                    initData(dispatch, data);
                    history.push(Routes.DashboardApprovers.path);
                } else if (userRoles.includes(8)) { // staff keuangan
                    localStorage.setItem("role", "finance-staff");
                    localStorage.setItem("isFinanceStaff", true);
                    await verifyUser({
                        payload,
                        inputOptions,
                        userEntitasAmount: getUserEntitas.data.length,
                        route: Routes.DashboardFinanceStaff.path,
                    });
                } else if (userRoles.includes(9)) { // kepala keuangan
                    localStorage.setItem("role", "finance-head");
                    localStorage.setItem("isFinanceHead", true);
                    await verifyUser({
                        payload,
                        inputOptions,
                        userEntitasAmount: getUserEntitas.data.length,
                        route: Routes.DashboardFinanceHead.path,
                    });
                } else if (userRoles.includes(10)) { // direktur eksekutif
                    localStorage.setItem("role", "executive-director");
                    localStorage.setItem("isExecutiveDirector", true);
                    initData(dispatch, data);
                    history.push(Routes.DashboardExecutiveDirector.path);
                } else if (userRoles.includes(11)) { // ketua yayasan
                    localStorage.setItem("role", "foundation-president");
                    localStorage.setItem("isFoundationPresident", true);
                    initData(dispatch, data);
                    history.push(Routes.DashboardFoundationPresident.path);
                }
            } else {
                Swal.fire("Oops..", 'Akun ini tidak punya akses ke Sistem Informasi Keuangan', "warning");
            }
        })
        .catch((error) => {
            Swal.fire("Oops..", 'Email atau Password salah', "error");
            setTimeout(() => {
                actions.setSubmitting(false);
            }, 500);
        });
        controller.abort();
    };
};

const verifyUser = (data) => {
    const { userEntitasAmount, inputOptions, payload, route } = data;
    if (userEntitasAmount > 1) {
        // if this user is registered in more than one entitas
        Swal.fire({
            title: 'Pilih salah satu Entitas',
            input: 'select',
            inputOptions: inputOptions,
            inputPlaceholder: 'Pilih Entitas',
            showCancelButton: false,
            allowOutsideClick: false,
            inputValidator: (value) => {
              return new Promise(async (resolve) => {
                if (value) {
                  resolve()
                  payload.entitas_id = value;
                  getEntitasById(payload, route);
                } else {
                  resolve('Pilih salah satu entitas')
                }
              })
            }
        });
    } else {
        getCostCentre(payload, route);
    }
}

const getEntitasById = (payload, route) => {
    const controller = new AbortController();
    const { dispatch, history, token, user_id, unit_id, entitas_id, unit } = payload;
    axios({
        url: `${process.env.REACT_APP_API_HOST}/keuangan/auth/get-entitas-by-id`,
        method: "post",
        responseType: 'json',
        headers: {
            'Authorization': token,
            'Accept': 'application/json'
        },
        signal: controller.signal,
        data: {
            entitas_id: entitas_id,
            user_id: user_id,
        },
    })
    .then((res) => {
        if (!res.data?.entitasUser && !res.data?.costCentreUser) {
            Swal.fire("Oops..", 'Akun ini tidak punya akses ke Sistem Informasi Keuangan', "warning");
            localStorage.clear();
            history.push(`/login`);
        } else {
            let data = {
                nama: unit,
                unit_id: unit_id,
                user_id: user_id,
                entitas: res.data.entitas.nama,
                entitas_id: res.data.entitas.id,
                entitas_user: res.data.entitasUser,
                cost_centre_user: res.data.costCentreUser,
            };
            localStorage.setItem('entitas', JSON.stringify(data));
            dispatch({
                type: SET_ENTITAS,
                payload: data
            });

            history.push(route);
        }
    }).catch((error) => {
        Swal.fire("Oops..", error?.response?.data, "error");
        localStorage.clear();
        history.push(`/login`);
    });
    controller.abort();
}

const getEntitasByUnit = (payload, route) => {
    const controller = new AbortController();
    const { dispatch, history, token, user_id, unit_id, unit, cost_centre_id } = payload;
    axios({
        url: `${process.env.REACT_APP_API_HOST}/keuangan/auth/get-entitas-by-unit`,
        method: "post",
        responseType: 'json',
        headers: {
            'Authorization': token,
            'Accept': 'application/json'
        },
        signal: controller.signal,
        data: {
            unit_id,
            user_id,
            cost_centre_id,
        },
    })
    .then((res) => {
        if (!res.data?.entitasUser && !res.data?.costCentreUser) {
            Swal.fire("Oops..", 'Akun ini tidak punya akses ke Sistem Informasi Keuangan', "warning");
            localStorage.clear();
            history.push(`/login`);
        } else {
            let data = {
                nama: unit,
                unit_id: unit_id,
                user_id: user_id,
                entitas: res.data.entitas.nama_entitas,
                entitas_id: res.data.entitas.entitas_id,
                entitas_user: res.data.entitasUser,
                cost_centre_user: res.data.costCentreUser,
            };
            localStorage.setItem('entitas', JSON.stringify(data));
            dispatch({
                type: SET_ENTITAS,
                payload: data
            });

            history.push(route);
        }
    }).catch((error) => {
        Swal.fire("Oops..", error?.response?.data, "error");
        localStorage.clear();
        history.push(`/login`);
    });
    controller.abort();
}

const getCostCentre = async (payload, route) => {
    const { token, history, user_id, unit_id } = payload;
    const formData = { user_id, unit_id };
    try {
        const options = {
            headers: {
                'Authorization': token,
                'Accept': 'application/json'
            }
        };
        const res = await axios.post(`${process.env.REACT_APP_API_HOST}/keuangan/auth/get-user-cost-centre`, formData, options);
        let inputOptions = {};
        for (const d of res.data) {
            inputOptions[d.cc_id] = d.nama;
        }
        if (res.data.length > 1) {
            // if this user is registered in more than one cost centre
            Swal.fire({
                title: 'Pilih salah satu Cost Centre',
                input: 'select',
                inputOptions: inputOptions,
                inputPlaceholder: 'Pilih Cost Centre',
                showCancelButton: false,
                allowOutsideClick: false,
                inputValidator: (value) => {
                    return new Promise(async (resolve) => {
                        if (value) {
                            resolve()
                            payload.cost_centre_id = value;
                            getEntitasByUnit(payload, route);
                        } else {
                            resolve('Pilih salah satu cost centre')
                        }
                    })
                }
            });
        } else {
            const cc_id = res.data[0]?.cc_id;
            payload.cost_centre_id = cc_id;
            getEntitasByUnit(payload, route);
        }
    } catch (error) {
        Swal.fire("Oops..", error?.response?.data, "error");
        localStorage.clear();
        history.push(`/login`);
    }
}

export const sendResetPasswordEmail = (formData, actions) => {
    return async () => {
        const controller = new AbortController();
        Swal.fire({
            text: "Sending password recovery mail...",
            imageUrl: "/assets/images/ajaxloader.gif",
            showConfirmButton: false,
            allowOutsideClick: false,
        });

        try {
            const options = {
                headers: {
                    'Accept': 'application/json',
                    'signal': controller.signal
                }
            };
            const res = await axios.post(`${process.env.REACT_APP_API_HOST}/users/send-reset-password-email`, formData, options);
            Swal.close();
            Swal.fire("Sukses", res.data, "success");

            actions.setSubmitting(false);
            actions.resetForm();
        } catch (error) {
            Swal.close();
            Swal.fire("Oops..", error?.response?.data, "error");
            setTimeout(() => {
                actions.setSubmitting(false);
            }, 500);
        }
        controller.abort();
    }
}
