import { combineReducers } from "redux";
import thunk from "redux-thunk";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import { SET_LOGOUT } from "./userInfo";
import userReducer from "./userInfo/userReducer";
import voucherReducer from "./voucher/voucherReducer";
import entitasReducer from "./entitas/entitasReducer";
import kodeBukuBesarReducer from "./kodeBukuBesar/kodeBukuBesarReducer";
import anggaranUnitReducer from "./anggaranUnits/anggaranReducer";
import anggaranAdminReducer from "./anggaranAdmin/anggaranReducer";
import entriTransaksiReducer from "./entriTransaksi/entriTransaksiReducer";
import workflowReducer from "./workflow/workflowReducer";
import approverReducer from "./approvers/approversReducer";
import riwayatPengajuanReducer from "./riwayatPengajuan/riwayatPengajuanReducer";
import laporanReducer from "./laporan/laporanReducer";
import dashboardReducer from "./dashboard/dashboardReducer";

// const rootPersistConfig = {
//     key: "root",
//     storage: storage,
// };

const userInfoPersistConfig = {
    key: "userInfo",
    storage: storage,
    whitelist: [
      "isLogin",
      "user",
      "entitas"
    ], // redux state to be store
};

const kodeBukuBesarPersistConfig = {
    key: "kodeBukuBesar",
    storage: storage,
    whitelist: [
      "instansi"
    ], // redux state to be store
}

const appReducer = combineReducers({
    userInfo: persistReducer(userInfoPersistConfig, userReducer),
    voucher: voucherReducer,
    entitas: entitasReducer,
    kodeBukuBesar: persistReducer(kodeBukuBesarPersistConfig, kodeBukuBesarReducer),
    anggaranUnit: anggaranUnitReducer,
    anggaranAdmin: anggaranAdminReducer,
    entriTransaksi: entriTransaksiReducer,
    workflow: workflowReducer,
    approvers: approverReducer,
    riwayatPengajuan: riwayatPengajuanReducer,
    laporan: laporanReducer,
    dashboard: dashboardReducer,
});

const rootReducer = (state, action) => {
    if (action.type === SET_LOGOUT) {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

export default configureStore({
    reducer: rootReducer,
    middleware: [thunk],
    devTools: true,
});
