import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import { Routes } from '../../routes';

import Loader from '../../components/Loader';
const load = (Component) => (props) => (
    <Suspense fallback={<Loader />}>
        <Component {...props} />
    </Suspense>
);

const Header = load(lazy(() => import('../../layouts/finance-head/header')));
const Sidebar = load(lazy(() => import('../../layouts/finance-head/sidebar')));
const Footer = load(lazy(() => import('../../layouts/finance-head/footer')));
const Assets = load(lazy(() => import('../../layouts/finance-head/assets')));
const FinanceHeadNotFound = load(lazy(() => import('./404-finance-head')));
const Dashboard = load(lazy(() => import('./dashboard')));
const ValidasiPengajuan = load(lazy(() => import('../approvers/validasi-pengajuan/validasi-pengajuan')));
const DetailPengajuan = load(lazy(() => import('../approvers/validasi-pengajuan/detail-pengajuan')));
const RiwayatPengajuan = load(lazy(() => import('../riwayat-pengajuan/unit/riwayat-pengajuan-list')));
const RiwayatPengajuanDetailPpbj = load(lazy(() => import('../riwayat-pengajuan/unit/riwayat-pengajuan-detail-ppbj')));
const RiwayatPengajuanDetailPtum = load(lazy(() => import('../riwayat-pengajuan/unit/riwayat-pengajuan-detail-ptum')));
const ValidasiPengajuanFinance = load(lazy(() => import('../approvers-finance/validasi-pengajuan/validasi-pengajuan')));
const DetailPengajuanFinance = load(lazy(() => import('../approvers-finance/validasi-pengajuan/detail-pengajuan')));
const RiwayatPengajuanFinance = load(lazy(() => import('../riwayat-pengajuan/finance/riwayat-pengajuan-list')));
const RiwayatPengajuanDetailFinanceBkkb = load(lazy(() => import('../riwayat-pengajuan/finance/riwayat-pengajuan-detail-bkkb')));
const RiwayatPengajuanDetailFinanceBtkb = load(lazy(() => import('../riwayat-pengajuan/finance/riwayat-pengajuan-detail-btkb')));
const RiwayatPengajuanDetailFinanceBj = load(lazy(() => import('../riwayat-pengajuan/finance/riwayat-pengajuan-detail-bj')));
const LaporanPosisiKeuangan = load(lazy(() => import('../finance-staff/laporan/posisi-keuangan')));
const LaporanKomprehensif = load(lazy(() => import('../finance-staff/laporan/laporan-komprehensif')));
const LaporanDaftarJurnal = load(lazy(() => import('../finance-staff/laporan/laporan-daftar-jurnal')));
const LaporanNeracaLajur = load(lazy(() => import('../finance-staff/laporan/laporan-neraca-lajur')));
const LaporanBukuBesar = load(lazy(() => import('../finance-staff/laporan/laporan-buku-besar')));

const Index = () => {
    return (<>
        <div className="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
            <Header />
            <div className="app-main">
                <Sidebar />
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <Switch>
                            <Route
                                path={Routes.DashboardFinanceHead.path}
                                render={props => <Dashboard {...props} />}
                            />
                            <Route
                                path={Routes.ValidasiPengajuanFinanceHead.path}
                                render={props => <ValidasiPengajuan {...props} />}
                            />
                            <Route
                                path={Routes.DetailPengajuanFinanceHead.path}
                                render={props => <DetailPengajuan {...props} />}
                            />
                            <Route
                                path={Routes.ValidasiPengajuanKeuanganFinanceHead.path}
                                render={props => <ValidasiPengajuanFinance {...props} />}
                            />
                            <Route
                                path={Routes.DetailPengajuanKeuanganFinanceHead.path}
                                render={props => <DetailPengajuanFinance {...props} />}
                            />
                            <Route
                                path={Routes.RiwayatPengajuanFinanceHead.path}
                                render={props => <RiwayatPengajuan {...props} />}
                            />
                            <Route
                                path={Routes.RiwayatPengajuanDetailPpbjFinanceHead.path}
                                render={props => <RiwayatPengajuanDetailPpbj {...props} />}
                            />
                            <Route
                                path={Routes.RiwayatPengajuanDetailPtumFinanceHead.path}
                                render={props => <RiwayatPengajuanDetailPtum {...props} />}
                            />
                            <Route
                                path={Routes.RiwayatPengajuanKeuanganFinanceHead.path}
                                render={props => <RiwayatPengajuanFinance {...props} />}
                            />
                            <Route
                                path={Routes.RiwayatPengajuanDetailKeuanganFinanceHead.path.replace(':type', 'bkkb')}
                                render={props => <RiwayatPengajuanDetailFinanceBkkb {...props} />}
                            />
                            <Route
                                path={Routes.RiwayatPengajuanDetailKeuanganFinanceHead.path.replace(':type', 'btkb')}
                                render={props => <RiwayatPengajuanDetailFinanceBtkb {...props} />}
                            />
                            <Route
                                path={Routes.RiwayatPengajuanDetailKeuanganFinanceHead.path.replace(':type', 'bj')}
                                render={props => <RiwayatPengajuanDetailFinanceBj {...props} />}
                            />
                            <Route
                                path={Routes.LaporanPosisiKeuanganFinanceHead.path}
                                render={props => <LaporanPosisiKeuangan {...props} />}
                            />
                            <Route
                                path={Routes.LaporanKeuanganKomprehensifFinanceHead.path}
                                render={props => <LaporanKomprehensif {...props} />}
                            />
                            <Route
                                path={Routes.LaporanDaftarJurnalFinanceHead.path}
                                render={props => <LaporanDaftarJurnal {...props} />}
                            />
                            <Route
                                path={Routes.LaporanNeracaLajurFinanceHead.path}
                                render={props => <LaporanNeracaLajur {...props} />}
                            />
                            <Route
                                path={Routes.LaporanBukuBesarFinanceHead.path}
                                render={props => <LaporanBukuBesar {...props} />}
                            />
                            <Route render={() => <FinanceHeadNotFound />} />
                        </Switch>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>

        <ToastContainer />
        <Assets />
    </>);
}

export default Index;
