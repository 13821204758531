export const VALIDATION_ERROR = "VALIDATION_ERROR";
export const DATA_UPDATED = "DATA_UPDATED";
export const SET_LOADING = "SET_LOADING";
export const SET_ANOTHER_LOADING = "SET_ANOTHER_LOADING";
export const ALL_ANGGARAN = "ALL_ANGGARAN";
export const ALL_ANGGARAN_TAHUNAN = "ALL_ANGGARAN_TAHUNAN";
export const ALL_ANGGARAN_BULANAN = "ALL_ANGGARAN_BULANAN";
export const REVIEW_ANGGARAN_TAHUNAN = "REVIEW_ANGGARAN_TAHUNAN";
export const ALL_ANGGARAN_NOTE = "ALL_ANGGARAN_NOTE";
export const KODE_AKUN1 = "KODE_AKUN1";
export const KODE_AKUN2 = "KODE_AKUN2";
export const KODE_AKUN3 = "KODE_AKUN3";
export const SET_LOADING1 = "SET_LOADING1";
export const SET_LOADING2 = "SET_LOADING2";
export const SET_LOADING3 = "SET_LOADING3";
export const RINCIAN_ADDED = "RINCIAN_ADDED";
export const DATA_EDIT = "DATA_EDIT";
export const ANGGARAN_DELETED = "ANGGARAN_DELETED";
export const SALDO_AWAL_UPDATED = "SALDO_AWAL_UPDATED";
export const DETAIL_SALDO_ANGGARAN_INTERNAL = "DETAIL_SALDO_ANGGARAN_INTERNAL";
