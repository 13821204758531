import {
    SET_LOADING,
    SET_ANOTHER_LOADING,
    SET_OTHER_LOADING,
    ALL_DATA_PPBJ,
    ALL_DATA_PTUM,
    ALL_DATA_BKKB,
    ALL_DATA_BTKB,
    ALL_DATA_BJ,
    DETAIL_PENGAJUAN_PPBJ,
    DETAIL_PENGAJUAN_PTUM,
    DETAIL_PENGAJUAN_BKKB,
    DETAIL_PENGAJUAN_BTKB,
    DETAIL_PENGAJUAN_BJ,
    ALL_UNIT,
} from ".";

const initialState = {
    historiPpbj: [],
    historiPtum: [],
    historiBkkb: [],
    historiBtkb: [],
    historiBj: [],
    detailHistoriPpbj: {},
    detailHistoriPtum: {},
    detailHistoriBkkb: {},
    detailHistoriBtkb: {},
    detailHistoriBj: {},
    isLoading: false,
    isAnotherLoading: false,
    isOtherLoading: false,
    units: [],
};

export default function riwayatPengajuanReducer(state = initialState, action) {
    switch (action.type) {
        case ALL_DATA_PPBJ:
            return { ...state, historiPpbj: action.payload };
        case ALL_DATA_PTUM:
            return { ...state, historiPtum: action.payload };
        case ALL_DATA_BKKB:
            return { ...state, historiBkkb: action.payload };
        case ALL_DATA_BTKB:
            return { ...state, historiBtkb: action.payload };
        case ALL_DATA_BJ:
            return { ...state, historiBj: action.payload };
        case DETAIL_PENGAJUAN_PPBJ:
            return { ...state, detailHistoriPpbj: action.payload };
        case DETAIL_PENGAJUAN_PTUM:
            return { ...state, detailHistoriPtum: action.payload };
        case DETAIL_PENGAJUAN_BKKB:
            return { ...state, detailHistoriBkkb: action.payload };
        case DETAIL_PENGAJUAN_BTKB:
            return { ...state, detailHistoriBtkb: action.payload };
        case DETAIL_PENGAJUAN_BJ:
            return { ...state, detailHistoriBj: action.payload };
        case SET_LOADING:
            return { ...state, isLoading: action.payload };
        case SET_ANOTHER_LOADING:
            return { ...state, isAnotherLoading: action.payload };
        case SET_OTHER_LOADING:
            return { ...state, isOtherLoading: action.payload };
        case ALL_UNIT:
            return { ...state, units: action.payload };
        default:
            return state;
    }
}