import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { login } from "../../stores/userInfo/userAction";
import { Routes } from '../../routes';

const FormLogin = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Email tidak valid")
                .required("Email wajib diisi"),
            password: Yup.string().required("Password wajib diisi"),
        }),
        onSubmit: (values, actions) => {
            const input = {
                email: values.email,
                password: values.password,
            };
            dispatch(login(input, history, actions));
        },
    });

    return (<>
        <div className="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-8">
            <div className="mx-auto app-login-box col-sm-12 col-md-10 col-lg-9">
                <div className="d-flex align-items-center">
                    <img src="/assets/images/Group-16.svg" alt="Logo" className="img-fluid" width="80" />
                    <h3 className="d-inline-block ml-3">Sistem Informasi<br />Keuangan Yayasan Hasnur Centre</h3>
                </div>
                <h4 className="mb-0 mt-4">
                    <span className="d-block">Selamat Datang Kembali,</span>
                    <span>Silakan Masuk ke Akun Anda</span>
                </h4>
                <div className="divider row"></div>
                <form onSubmit={formik.handleSubmit} noValidate>
                    <div className="row">
                        <div className="col-md-6 form-group">
                            <label>Email</label>
                            <input
                                type="email"
                                name="email"
                                className={formik.errors['email'] && formik.touched['email'] ? 'form-control is-invalid' : 'form-control'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={formik.isDisabled}
                                value={formik.values.email}
                            />
                            <em className={formik.errors['email'] && formik.touched['email'] ? `error invalid-feedback` : `d-none`}>{formik.errors['email']}</em>
                        </div>
                        <div className="col-md-6 form-group">
                            <label>Password</label>
                            <input
                                type="password"
                                name="password"
                                className={formik.errors['password'] && formik.touched['password'] ? 'form-control is-invalid' : 'form-control'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={formik.isDisabled}
                                value={formik.values.password}
                            />
                            <em className={formik.errors['password'] && formik.touched['password'] ? `error invalid-feedback` : `d-none`}>{formik.errors['password']}</em>
                        </div>
                    </div>
                    <div className="position-relative form-check">
                        <input name="check" id="exampleCheck" type="checkbox" className="form-check-input" />
                        <label htmlFor="exampleCheck" className="form-check-label">Tetap Masuk</label>
                    </div>
                    <hr />
                    <div className="form-group text-right">
                        <Link to={Routes.RecoverPassword.path} className="btn btn-link mr-3">Lupa Password</Link>
                        <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>
                            {formik.isSubmitting ? <img src="/assets/images/ajaxloader.gif" className="img-fluid mr-1" style={{width: '18px'}} alt="" /> : <i className="fas fa-sign-in-alt mr-1"></i>}
                            Masuk ke Dashboard
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </>);
}
 
export default FormLogin;
