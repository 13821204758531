export const VALIDATION_ERROR = "VALIDATION_ERROR";
export const SET_LOADING = "SET_LOADING";
export const SET_ANOTHER_LOADING = "SET_ANOTHER_LOADING";
export const ALL_ANGGARAN = "ALL_ANGGARAN";
export const ALL_ANGGARAN_REVIEW = "ALL_ANGGARAN_REVIEW";
export const ALL_ANGGARAN_TAHUNAN = "ALL_ANGGARAN_TAHUNAN";
export const ALL_ANGGARAN_BULANAN = "ALL_ANGGARAN_BULANAN";
export const ALL_ANGGARAN_REPORT = "ALL_ANGGARAN_REPORT";
export const ALL_ANGGARAN_NOTE = "ALL_ANGGARAN_NOTE";
export const LATEST_THN_ANGGARAN = "LATEST_THN_ANGGARAN";
export const COST_CENTRE_LIST = "COST_CENTRE_LIST";
export const GIVE_NOTE = "GIVE_NOTE";
export const APPROVAL = "APPROVAL";
export const DETAIL_REALISASI_PROGNOSA = "DETAIL_REALISASI_PROGNOSA";
export const CHART_REALISASI_PROGNOSA = "CHART_REALISASI_PROGNOSA";
