import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function FoundationPresidentRoute({component: Component, ...restProps}) {
    const foundationPresident = localStorage.getItem('isFoundationPresident');

    return <Route
        {...restProps}
        render = {props => {
            return foundationPresident ? <Component {...props} /> : <Redirect to={{pathname: '/login'}} />
        }}
    />
}

export default FoundationPresidentRoute;