import React from 'react';
import { Redirect } from 'react-router-dom';
import FormLogin from './form-login';
import FormOTP from './form-otp';
import { Routes } from "../../routes";

const Login = () => {
    if (localStorage.getItem('admin-token')) {
        return <Redirect to={Routes.DashboardAdmin.path} />
    } else if (localStorage.getItem('user-token')) {
        return <Redirect to={Routes.DashboardUser.path} />
    }

    return (
    <>
        <div className="app-container app-theme-white body-tabs-shadow">
            <div className="app-container">
                <div className="h-100">
                    <div className="h-100 no-gutters row">
                        <div className="d-none d-lg-block col-lg-4">
                            <div className="slider-light">
                                <div className="slick-slider">
                                    <div>
                                        <div className="position-relative h-100 d-flex justify-content-center align-items-center" tabIndex="-1">
                                            <div className="slide-img-bg" style={{ backgroundImage: 'url("/assets/images/flat-lay-business-concept_53876-64851/flat-lay-business-concept_53876-64851@2x.png")', opacity: '1' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FormLogin />
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}
 
export default Login;