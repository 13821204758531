import {
    SET_LOADING,
    LIST_PENGAJUAN_PPBJ,
    LIST_PENGAJUAN_PTUM,
    LIST_PENGAJUAN_BKKB,
    LIST_PENGAJUAN_BTKB,
    LIST_PENGAJUAN_BJ,
    DETAIL_PENGAJUAN,
} from ".";

const initialState = {
    listPengajuanPpbj: [],
    listPengajuanPtum: [],
    listPengajuanBkkb: [],
    listPengajuanBtkb: [],
    listPengajuanBj: [],
    isLoading: false,
    detailPengajuan: {},
};

export default function entriTransaksiReducer(state = initialState, action) {
    switch (action.type) {
        case LIST_PENGAJUAN_PPBJ:
            return { ...state, listPengajuanPpbj: action.payload };
        case LIST_PENGAJUAN_PTUM:
            return { ...state, listPengajuanPtum: action.payload };
        case LIST_PENGAJUAN_BKKB:
            return { ...state, listPengajuanBkkb: action.payload };
        case LIST_PENGAJUAN_BTKB:
            return { ...state, listPengajuanBtkb: action.payload };
        case LIST_PENGAJUAN_BJ:
            return { ...state, listPengajuanBj: action.payload };
        case SET_LOADING:
            return { ...state, isLoading: action.payload };
        case DETAIL_PENGAJUAN:
            return { ...state, detailPengajuan: action.payload };
        default:
            return state;
    }
}