import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function UnitsRoute({component: Component, ...restProps}) {
    const units = localStorage.getItem('isUnits');

    return <Route
        {...restProps}
        render = {props => {
            return units ? <Component {...props} /> : <Redirect to={{pathname: '/login'}} />
        }}
    />
}

export default UnitsRoute;