import React, { lazy, Suspense, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import { Routes } from '../../routes';

import Loader from '../../components/Loader';
const load = (Component) => (props) => (
    <Suspense fallback={<Loader />}>
        <Component {...props} />
    </Suspense>
);

const Header = load(lazy(() => import('../../layouts/units/header')));
const Sidebar = load(lazy(() => import('../../layouts/units/sidebar')));
const Footer = load(lazy(() => import('../../layouts/units/footer')));
const Assets = load(lazy(() => import('../../layouts/units/assets')));
const UnitsNotFound = load(lazy(() => import('./404-units')));
const Dashboard = load(lazy(() => import('./dashboard')));
const Anggaran = load(lazy(() => import('./anggaran/anggaran')));
const TambahAnggaranTahunan = load(lazy(() => import('./anggaran/tambah-anggaran-tahunan')));
const AnggaranTahunan = load(lazy(() => import('./anggaran/anggaran-tahunan')));
const AnggaranBulanan = load(lazy(() => import('./anggaran/anggaran-bulanan')));
const FormulirAnggaran = load(lazy(() => import('./anggaran/formulir-anggaran')));
const FormRincianAnggaran = load(lazy(() => import('./anggaran/form-rincian-anggaran')));
const FormCopyAnggaran = load(lazy(() => import('./anggaran/form-copy-anggaran')));
const SetupSaldoAnggaranInternal = load(lazy(() => import('./anggaran/setup-saldo-anggaran-internal')));
const EntriTransaksi = load(lazy(() => import('./entri-transaksi/entri-transaksi')));
const PPBJList = load(lazy(() => import('./entri-transaksi/ppbj/ppbj-list')));
const PPBJForm = load(lazy(() => import('./entri-transaksi/ppbj/ppbj-form')));
const PPBJDetail = load(lazy(() => import('./entri-transaksi/ppbj/ppbj-detail')));
const PPList = load(lazy(() => import('./entri-transaksi/pp/pp-list')));
const PPForm = load(lazy(() => import('./entri-transaksi/pp/pp-form')));
const PUMList = load(lazy(() => import('./entri-transaksi/pum/pum-list')));
const PUMForm = load(lazy(() => import('./entri-transaksi/pum/pum-form')));
const PTUMList = load(lazy(() => import('./entri-transaksi/ptum/ptum-list')));
const PTUMForm = load(lazy(() => import('./entri-transaksi/ptum/ptum-form')));
const PTUMDetail = load(lazy(() => import('./entri-transaksi/ptum/ptum-detail')));
const ValidasiPengajuan = load(lazy(() => import('../approvers/validasi-pengajuan/validasi-pengajuan')));
const DetailPengajuan = load(lazy(() => import('../approvers/validasi-pengajuan/detail-pengajuan')));
const RiwayatPengajuan = load(lazy(() => import('../riwayat-pengajuan/unit/riwayat-pengajuan-list')));
const RiwayatPengajuanDetailPpbj = load(lazy(() => import('../riwayat-pengajuan/unit/riwayat-pengajuan-detail-ppbj')));
const RiwayatPengajuanDetailPtum = load(lazy(() => import('../riwayat-pengajuan/unit/riwayat-pengajuan-detail-ptum')));

const Index = () => {
    const [formAnggaran, setFormAnggaran] = useState([]);
    const [copiedDataAnggaran, setCopiedDataAnggaran] = useState({});
    const [saldoAnggaranInternal, setSaldoAnggaranInternal] = useState([]);

    return (<>
        <div className="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
            <Header />
            <div className="app-main">
                <Sidebar />
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <Switch>
                            <Route
                                path={Routes.DashboardUnits.path}
                                render={props => <Dashboard {...props} />}
                            />
                            <Route
                                path={Routes.AnggaranUnits.path}
                                render={props => <Anggaran {...props} />}
                            />
                            <Route
                                path={Routes.AnggaranTahunanUnits.path}
                                render={props => <AnggaranTahunan {...props}
                                    saldoAnggaranInternalData={(val) => setSaldoAnggaranInternal(val)} />}
                            />
                            <Route
                                path={Routes.AnggaranBulananUnits.path}
                                render={props => <AnggaranBulanan {...props}
                                    copiedAnggaranData={(val) => setCopiedDataAnggaran(val)} />}
                            />
                            <Route
                                path={Routes.FormulirAnggaranUnits.path}
                                render={props => <FormulirAnggaran {...props}
                                    formAnggaranData={(val) => setFormAnggaran(val)} />}
                            />
                            <Route
                                path={Routes.FormKasBankUnits.path}
                                exact
                                render={props => <EntriTransaksi {...props} />}
                            />
                            <Route
                                path={Routes.PPBJListUnits.path}
                                render={props => <PPBJList {...props} />}
                            />
                            <Route
                                path={Routes.PPBJFormUnits.path}
                                render={props => <PPBJForm {...props} />}
                            />
                            <Route
                                path={Routes.PPBJDetailUnits.path}
                                render={props => <PPBJDetail {...props} />}
                            />
                            <Route
                                path={Routes.PPListUnits.path}
                                render={props => <PPList {...props} />}
                            />
                            <Route
                                path={Routes.PPFormUnits.path}
                                render={props => <PPForm {...props} />}
                            />
                            <Route
                                path={Routes.PUMListUnits.path}
                                render={props => <PUMList {...props} />}
                            />
                            <Route
                                path={Routes.PUMFormUnits.path}
                                render={props => <PUMForm {...props} />}
                            />
                            <Route
                                path={Routes.PTUMListUnits.path}
                                render={props => <PTUMList {...props} />}
                            />
                            <Route
                                path={Routes.PTUMFormUnits.path}
                                render={props => <PTUMForm {...props} />}
                            />
                            <Route
                                path={Routes.PTUMDetailUnits.path}
                                render={props => <PTUMDetail {...props} />}
                            />
                            <Route
                                path={Routes.ValidasiPengajuanUnits.path}
                                render={props => <ValidasiPengajuan {...props} />}
                            />
                            <Route
                                path={Routes.DetailPengajuanUnits.path}
                                render={props => <DetailPengajuan {...props} />}
                            />
                            <Route
                                path={Routes.RiwayatPengajuanUnits.path}
                                render={props => <RiwayatPengajuan {...props} />}
                            />
                            <Route
                                path={Routes.RiwayatPengajuanDetailPpbjUnits.path}
                                render={props => <RiwayatPengajuanDetailPpbj {...props} />}
                            />
                            <Route
                                path={Routes.RiwayatPengajuanDetailPtumUnits.path}
                                render={props => <RiwayatPengajuanDetailPtum {...props} />}
                            />
                            <Route render={() => <UnitsNotFound />} />
                        </Switch>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>

        <TambahAnggaranTahunan />
        <FormRincianAnggaran data={formAnggaran} />
        <FormCopyAnggaran data={copiedDataAnggaran} />
        <SetupSaldoAnggaranInternal data={saldoAnggaranInternal} />

        <ToastContainer />
        <Assets />
    </>);
}
 
export default Index;
