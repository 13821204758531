import {
    SET_LOGIN,
    SET_LOGOUT,
    SET_TOKEN,
    SET_ENTITAS,
} from ".";

const initialState = {
    isLogin: false,
    user: {},
    entitas: {}
};

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case SET_LOGOUT:
          return { ...state, isLogin: false };
        case SET_LOGIN:
          return { ...state, isLogin: true };
        case SET_TOKEN:
          return { ...state, user: action.payload };
        case SET_ENTITAS:
            return { ...state, entitas: action.payload };
        default:
          return state;
    }
}