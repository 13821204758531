import {
    ALL_ANGGARAN,
    ALL_ANGGARAN_TAHUNAN,
    ALL_ANGGARAN_BULANAN,
    ALL_ANGGARAN_NOTE,
    KODE_AKUN1,
    KODE_AKUN2,
    KODE_AKUN3,
    DATA_UPDATED,
    VALIDATION_ERROR,
    SET_LOADING,
    SET_ANOTHER_LOADING,
    SET_LOADING1,
    SET_LOADING2,
    SET_LOADING3,
    RINCIAN_ADDED,
    DATA_EDIT,
    REVIEW_ANGGARAN_TAHUNAN,
    ANGGARAN_DELETED,
    SALDO_AWAL_UPDATED,
    DETAIL_SALDO_ANGGARAN_INTERNAL,
} from ".";

const initialState = {
    allAnggaran: [],
    allAnggaranTahunan: [],
    allAnggaranBulanan: {},
    reviewAnggaranTahunan: [],
    allAnggaranNote: [],
    dataAdded: "",
    kodeAkun1: [],
    kodeAkun2: [],
    kodeAkun3: [],
    validationError: {},
    isUpdated: "",
    isLoading: false,
    isAnotherLoading: false,
    isLoadingOpt1: false,
    isLoadingOpt2: false,
    isLoadingOpt3: false,
    dataRincian: [],
    dataEdit: [],
    saldoAwalUpdated: "",
    detailSaldoAnggaranInternal: {},
};

export default function anggaranUnitReducer(state = initialState, action) {
    switch (action.type) {
        case ALL_ANGGARAN:
            return { ...state, allAnggaran: action.payload };
        case ALL_ANGGARAN_TAHUNAN:
            return { ...state, allAnggaranTahunan: action.payload };
        case ALL_ANGGARAN_BULANAN:
            return { ...state, allAnggaranBulanan: action.payload };
        case REVIEW_ANGGARAN_TAHUNAN:
            return { ...state, reviewAnggaranTahunan: action.payload };
        case ALL_ANGGARAN_NOTE:
            return { ...state, allAnggaranNote: action.payload };
        case KODE_AKUN1:
            return { ...state, kodeAkun1: action.payload };
        case KODE_AKUN2:
            return { ...state, kodeAkun2: action.payload };
        case KODE_AKUN3:
            return { ...state, kodeAkun3: action.payload };
        case DATA_UPDATED:
            return { ...state, dataAdded: action.payload };
        case VALIDATION_ERROR:
            return { ...state, validationError: action.payload };
        case SET_LOADING:
            return { ...state, isLoading: action.payload };
        case SET_ANOTHER_LOADING:
            return { ...state, isAnotherLoading: action.payload };
        case SET_LOADING1:
            return { ...state, isLoadingOpt1: action.payload };
        case SET_LOADING2:
            return { ...state, isLoadingOpt2: action.payload };
        case SET_LOADING3:
            return { ...state, isLoadingOpt3: action.payload };
        case RINCIAN_ADDED:
            return { ...state, dataRincian: action.payload };
        case DATA_EDIT:
            return { ...state, dataEdit: action.payload };
        case ANGGARAN_DELETED:
            return { ...state, isUpdated: action.payload };
        case SALDO_AWAL_UPDATED:
            return { ...state, saldoAwalUpdated: action.payload };
        case DETAIL_SALDO_ANGGARAN_INTERNAL:
            return { ...state, detailSaldoAnggaranInternal: action.payload };
        default:
            return state;
    }
}
